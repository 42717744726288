import React, { Component } from "react";
import { Container } from 'reactstrap';
import $ from "jquery";
import Api from '../serviceCall';
import MaterialTable from "material-table";
import Switch from "react-bootstrap-switch";
import PropTypes from 'prop-types';
import { deviceDetect } from "react-device-detect";
import { TimeoutCounter } from './TimeoutCounter';
export class CreateDatabase extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired
    };
    constructor(props) {
        super(props);
        this.ocpRef = React.createRef();
        this.ucpRef = React.createRef();
        this.uzipRef = React.createRef();
        this.zipRef = React.createRef();
        this.mcpRef = React.createRef();
        this.acpRef = React.createRef();
        this.ccpnRef = React.createRef();
        this.ccpnsRef = React.createRef();
        this.state = {
            allowViewResults: "Yes",
            allowPlaceOrder: "Yes",
            allowViewNotes: "Yes",
            allowViewAuth: "Yes",
            allowViewStatus: "Yes",
            allowOverrideAccess: "Yes",
            allowExportSearchResults: "Yes",
            allowPerformanceMetrics: "Yes",
            AllowDefaultOfficeCA: "Yes",
            AllowCSRMessaging: "No",
            Loading: true,
            CropNumber: "", CarrierName: "", Carrier: "",
            RequiredFieldsList: [], originalFieldName: "", fieldType: "",
            isDefault: false,
            isFieldsEditModalopen: false,
            orderIsActiveSelectedValue: "",
            orderisRequiredSelectedValue: "",
            Address: "", City: "", UCity: "", State: "", ZipCode: "",
            MainContactName: "", MainContactEmail: "", MainContactPhone: "",
            AlternateContactName: "", AlternateContactEmail: "", AlternateContactPhone: "",
            CustomerServiceContactName: "", CustomerServiceContactEmail: "", CustomerServiceContactPhone: "",
            CompanyType: "", CompanyCode: "", StatesList: [], CompanyTypeList: [],
            OOName: "", OOAccountNo: "", OOCode: "", OOEmail: "", OOPhone: "",
            rOOName: false, rOOAccountNo: false, rOOCode: false, rOOEmail: false, rOOPhone: false,
            rCropNumber: false, rCarrierName: false,
            rAddress: false, rCity: false, rUCity: false, rState: false, rZipCode: false,
            rMainContactName: false, rMainContactEmail: false, rMainContactPhone: false,
            rAlternateContactName: false, rAlternateContactEmail: false, rAlternateContactPhone: false,
            rCustomerServiceContactName: false, rCustomerServiceContactEmail: false, rCustomerServiceContactPhone: false,
            rCompanyType: false, rCompanyCode: false, toasterCla: false, toasterredCla: false, toaster: "",
            FirstName: "", LastName: "", UEmail: "", UPhone: "", UAddress: "", UState: "", UZip: "", AgentId: "",
            rFirstName: false, rLastName: false, rUEmail: false, rUPhone: false, rUAddress: false, rUState: false, rUZip: false, rAgentId: false,
            Summarization: false, OHB: "", rOHB: false, SK: "", rSK: false,
            CustomFieldName: "", IsActive: false, IsRequired: false, customField: false, fieldId: "",
            ShowFieldSetup: false,
            CarrierManagerFields: false,
            PatientInfoFields: false,
            AttorneyInfoFields: false,
            AdjusterInfoFields: false,
            FacilityInfoFields: false,
            AgentInfoFields: false,
            FirstNameisHide: false,
            LastNameisHide: false,
            PCAddressisHide: false,
            PCCityisHide: false,
            PCZipCodeisHide: false,
            Phone1isHide: false,
            DoctorFirstNameisHide: false,
            DoctorLastNameisHide: false,
            DoctorCityisHide: false,
            DoctorZipCodeisHide: false,
            PhoneisHide: false,
            DOBisHide: false,
            GenderisHide: false,
            Phone1TypeisHide: false,
            PCSTisHide: false,
            DrStateIdisHide: false,
            RequiredFieldsSec1: [],
            RequiredFieldsSec4: [],
            RequiredFieldsSec5: [],
            RequiredFieldsSec6: [],
            RequiredFieldsSec7: [],
            RequiredFieldsSec13: [],
            ShowAfterCompanyTypeSelection: false,
            EnableSSOConnection: false,
            rIdentifier: false,
            vIdentifier: false,
            Identifier: "",
            EnableMFA: true,
            MFATokenType: "1",
            MFATokenExpiryDays: "30",
            MFATokenExpiryMin: "5",
            MFATokenGenType: "4",
            MFAUserwithIP: "mfaipwithuser",
            MFATokenLength: "8",
            MFATokenTypeList: '',
            MFATokenExpiryDaysList: '',
            MFATokenExpiryMinList: '',
            MFATokenGenTypeList: '',
            iseNoahAdmin: true,
            RetentionPeriodDays: 120,
            ProductionPurgeDays: 365,
            OfflineArchivePurgeDays: 730,
            rRetentionPeriodDays: false,
            rProductionPurgeDays: false,
            rOfflineArchivePurgeDays: false,
            IpAddressListing: false,
            ipAddressChange: false,
            rpassUpdateDays: false,
            DaysForEnforcePass: 45,
            rRemPasswordCount: false,
            RememberPassCount: 10,
            rInactiveDays: false,
            InactiveDays: 90,
            PassNeverExpires: false,
            NeverInactiveUser: false,
            IsPassNeverExpires: false,
            IsNeverInactiveUser: false,
            rFromIpAddress: false,
            FromIpAddress: "",
            rToIpAddress: false,
            ToIpAddress: "",
            IpList: [],
            IpAddressList: [],
            RememberPassword: false,
            greycheckbox: false,
            ipSwitchGrey: false,
            CheckedArr: [],
            markCheckbox: false,
            deleteAlert: false,
            RHB: "", rRHB: false,
            SummaryIsRequired: false,
            UnderwriterFields: false,
            RequiredFieldsSec14: [],
            orderSummaryIsRequiredSelectedValue: "",
            CustomerServiceContactNameforSummary: "", rCustomerServiceContactNameforSummary: false, CustomerServiceContactEmailforSummary: "", rCustomerServiceContactEmailforSummary: false, rCustomerServiceContactPhoneforSummary: false, CustomerServiceContactPhoneforSummary: "", APSCheckbox: false, SummaryCheckbox: false, IsAPSChecked: false, IsSummaryChecked: false, ShowAPSDiv: false, ShowSummaryDiv: false, SummaryCropNumber: "", rSummaryCropNumber: false,
            EnableOfficebyAccountNum:false,
        };
        this.handleallowViewResultsChange = this.handleallowViewResultsChange.bind(this);
        this.handleallowPlaceOrderChange = this.handleallowPlaceOrderChange.bind(this);
        this.handleallowViewNotesChange = this.handleallowViewNotesChange.bind(this);
        this.handleallowViewAuthChange = this.handleallowViewAuthChange.bind(this);
        this.handleallowViewStatusChange = this.handleallowViewStatusChange.bind(this);
        this.handleallowOverrideAccessChange = this.handleallowOverrideAccessChange.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.handleCustomFieldNameChange = this.handleCustomFieldNameChange.bind(this);
        this.handleCropNumberChange = this.handleCropNumberChange.bind(this);
        this.handleCarrierNameChange = this.handleCarrierNameChange.bind(this);
        this.handleCompanyTypeChange = this.handleCompanyTypeChange.bind(this);
        this.handleSKChange = this.handleSKChange.bind(this);
        this.handleCompanyCodeChange = this.handleCompanyCodeChange.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleZipCodeChange = this.handleZipCodeChange.bind(this);
        this.handleMainContactNameChange = this.handleMainContactNameChange.bind(this);
        this.handleMainContactPhoneChange = this.handleMainContactPhoneChange.bind(this);
        this.handleMainContactEmailChange = this.handleMainContactEmailChange.bind(this);
        this.handleAlternateContactPhoneChange = this.handleAlternateContactPhoneChange.bind(this);
        this.handleAlternateContactNameChange = this.handleAlternateContactNameChange.bind(this);
        this.handleAlternateContactEmailChange = this.handleAlternateContactEmailChange.bind(this);
        this.handleCustomerServiceContactNameChange = this.handleCustomerServiceContactNameChange.bind(this);
        this.handleCustomerServiceContactEmailChange = this.handleCustomerServiceContactEmailChange.bind(this);
        this.handleCustomerServiceContactPhoneChange = this.handleCustomerServiceContactPhoneChange.bind(this);
        this.handleOONameChange = this.handleOONameChange.bind(this);
        this.handleOOCodeChange = this.handleOOCodeChange.bind(this);
        this.handleOOEmailChange = this.handleOOEmailChange.bind(this);
        this.handleOOPhoneChange = this.handleOOPhoneChange.bind(this);
        this.handleOOAccNumChange = this.handleOOAccNumChange.bind(this);
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleUEmailChange = this.handleUEmailChange.bind(this);
        this.handleUPhoneChange = this.handleUPhoneChange.bind(this);
        this.handleUCityChange = this.handleUCityChange.bind(this);
        this.handleUAddressChange = this.handleUAddressChange.bind(this);
        this.handleUStateChange = this.handleUStateChange.bind(this);
        this.handleUZipChange = this.handleUZipChange.bind(this);
        this.handleAgentIdChange = this.handleAgentIdChange.bind(this);
        this.handleSummarization = this.handleSummarization.bind(this);
        this.handleOHBChange = this.handleOHBChange.bind(this);
        this.handleSubmitCompanyDetails = this.handleSubmitCompanyDetails.bind(this);
        this.handleClearDetails = this.handleClearDetails.bind(this);
        this.testAlphaNumeric = this.testAlphaNumeric.bind(this);
        this.testEXT = this.testEXT.bind(this);
        this.testEmail = this.testEmail.bind(this);
        this.testPhone = this.testPhone.bind(this);
        this.testZip = this.testZip.bind(this);
        this.testZip5 = this.testZip5.bind(this);
        this.testAlphawithSpace = this.testAlphawithSpace.bind(this);
        this.testAlphawithSpaceforCity = this.testAlphawithSpaceforCity.bind(this);
        this.testAlphaNumericwithSpaceForName = this.testAlphaNumericwithSpaceForName.bind(this);
        this.testAlphaNumericwithSpaceForCarrier = this.testAlphaNumericwithSpaceForCarrier.bind(this);
        this.testAlphaNumericwithSpaceForEmail = this.testAlphaNumericwithSpaceForEmail.bind(this);
        this.testAlphaNumericwithSpaceForEmailNew = this.testAlphaNumericwithSpaceForEmailNew.bind(this);
        this.testEmailNew = this.testEmailNew.bind(this);
        this.toasterfun2 = this.toasterfun2.bind(this);
        this.toasterredfun2 = this.toasterredfun2.bind(this);
        this.toasterredfun = this.toasterredfun.bind(this);
        this.onClickopenFieldEditPopup = this.onClickopenFieldEditPopup.bind(this);
        this.handleIsActiveChange = this.handleIsActiveChange.bind(this);
        this.handleIsRequiredChange = this.handleIsRequiredChange.bind(this);
        this.handleUpdateFieldInformation_New = this.handleUpdateFieldInformation_New.bind(this);
        this.handleUpdateFieldInformation = this.handleUpdateFieldInformation.bind(this);
        this.handleblurMainContactEmailChange = this.handleblurMainContactEmailChange.bind(this);
        this.handleblurServiceContactEmailChange = this.handleblurServiceContactEmailChange.bind(this);
        this.handleblurEmailChange = this.handleblurEmailChange.bind(this);
        this.handleblurOOEmailChange = this.handleblurOOEmailChange.bind(this);
        this.handleblurAltContactEmailChange = this.handleblurAltContactEmailChange.bind(this);
        this.handleEnableSSOConnectionSwitch = this.handleEnableSSOConnectionSwitch.bind(this);
        this.handleIdentifierChange = this.handleIdentifierChange.bind(this);
        this.handleallowCarrierLevelPMAChange = this.handleallowCarrierLevelPMAChange.bind(this);
        this.handleallowCarrierLeverCSRMessagingChange = this.handleallowCarrierLeverCSRMessagingChange.bind(this);
        this.handleallowCarrierLevelESRChange = this.handleallowCarrierLevelESRChange.bind(this);
        this.handleEnableMFASwitch = this.handleEnableMFASwitch.bind(this);
        this.handleMFATokenTypeChange = this.handleMFATokenTypeChange.bind(this);
        this.handleMFATokenExpiryDaysChange = this.handleMFATokenExpiryDaysChange.bind(this);
        this.handleMFATokenExpiryMinChange = this.handleMFATokenExpiryMinChange.bind(this);
        this.handleMFATokenGenTypeChange = this.handleMFATokenGenTypeChange.bind(this);
        this.handleMFATokenLengthChange = this.handleMFATokenLengthChange.bind(this);
        this.handleMFAIpWithUserChange = this.handleMFAIpWithUserChange.bind(this);
        this.handleRetentionPeriodDaysChange = this.handleRetentionPeriodDaysChange.bind(this);
        this.handleOfflineArchivePurgeDaysChange = this.handleOfflineArchivePurgeDaysChange.bind(this);
        this.handleProductionPurgeDaysChange = this.handleProductionPurgeDaysChange.bind(this);
        this.handleIpAddressChange = this.handleIpAddressChange.bind(this);
        this.handlePassUpdateDaysChange = this.handlePassUpdateDaysChange.bind(this);
        this.handleRemPasswordDaysChange = this.handleRemPasswordDaysChange.bind(this);
        this.handleInactiveDaysChange = this.handleInactiveDaysChange.bind(this);
        this.handlePassNeverExpires = this.handlePassNeverExpires.bind(this);
        this.handleInactiveUser = this.handleInactiveUser.bind(this);
        this.handleAddIpAddress = this.handleAddIpAddress.bind(this);
        this.handleFromIpAddressChange = this.handleFromIpAddressChange.bind(this);
        this.handleToIpAddressChange = this.handleToIpAddressChange.bind(this);
        this.ValidateIPaddress = this.ValidateIPaddress.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.AddIpAddressToList = this.AddIpAddressToList.bind(this);
        this.selectAllCheckboxes = this.selectAllCheckboxes.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.handleDeleteIpAddress = this.handleDeleteIpAddress.bind(this);
        this.handleOpendeletebttn = this.handleOpendeletebttn.bind(this);
        this.handledeletebttn = this.handledeletebttn.bind(this);
        this.handleClearIpAddress = this.handleClearIpAddress.bind(this);
        this.handleallowDefaultOfficeforClientAdminUsers = this.handleallowDefaultOfficeforClientAdminUsers.bind(this);
        this.handleCustomerServiceContactNameChangeforSummary = this.handleCustomerServiceContactNameChangeforSummary.bind(this);
        this.handleCustomerServiceContactEmailChangeforSummary = this.handleCustomerServiceContactEmailChangeforSummary.bind(this);
        this.handleblurCustomerServiceContactEmailChangeforSummary = this.handleblurCustomerServiceContactEmailChangeforSummary.bind(this);
        this.handleCustomerServiceContactPhoneChangeforSummary = this.handleCustomerServiceContactPhoneChangeforSummary.bind(this);
        this.handleAPSChecked = this.handleAPSChecked.bind(this);
        this.handleSummaryChecked = this.handleSummaryChecked.bind(this);
        this.handleSummaryCropNumberChange = this.handleSummaryCropNumberChange.bind(this);
        this.ShowUnderwriterFields = this.ShowUnderwriterFields.bind(this);
        this.handleSummaryIsRequiredChange = this.handleSummaryIsRequiredChange.bind(this);
        this.handleRHBChange = this.handleRHBChange.bind(this);
        this.handleEnableOfficeAccountSwitch = this.handleEnableOfficeAccountSwitch.bind(this);
        this.UserLogoutTime = this.UserLogoutTime.bind(this);
        this.UserLogOut = this.UserLogOut.bind(this);
        this.testAlphaNumericWithHyphen = this.testAlphaNumericWithHyphen.bind(this);
    }
    componentDidMount() {
        let currentComponent = this;
        localStorage.removeItem('PressedKey');
        localStorage.removeItem('IsShiftPressed');
        localStorage.removeItem('IsCtrlPressed');


        document.addEventListener('keydown', function (event) {
            localStorage.setItem('PressedKey', event.key);
            localStorage.setItem('IsCtrlPressed', event.ctrlKey);
            localStorage.setItem('IsShiftPressed', event.shiftKey);
        });
        window.addEventListener('beforeunload', function (e) {
            let IsTimeOut = localStorage.getItem("IsTimeOut");
            let PressedKey = localStorage.getItem("PressedKey");
            let IsShiftPressed = localStorage.getItem("IsShiftPressed");
            let IsCtrlPressed = localStorage.getItem("IsCtrlPressed");
            let IsLogOutClicked = localStorage.getItem("UserLogOutButton");
            if ((IsShiftPressed && IsCtrlPressed && (PressedKey == 'r' || PressedKey == 'R')) || PressedKey == 'F5' || (IsCtrlPressed && (PressedKey == 'r' || PressedKey == 'R')) || IsTimeOut == 'false' || IsLogOutClicked == 'true') {
                e.preventDefault();
                e.returnValue = '';
            }
        });
        window.addEventListener('unload', function (event) {
            let PressedKey = localStorage.getItem("PressedKey");
            let IsShiftPressed = localStorage.getItem("IsShiftPressed");
            let IsCtrlPressed = localStorage.getItem("IsCtrlPressed");
            localStorage.setItem('isAuthenticated', 'true');
            if ((IsShiftPressed && IsCtrlPressed && (PressedKey == 'r' || PressedKey == 'R')) || PressedKey == 'F5' || (IsCtrlPressed && (PressedKey == 'r' || PressedKey == 'R'))) {
                //Normal refresh will happen without Logging out.
            } else {
                localStorage.setItem('isAuthenticated', 'false');
                currentComponent.UserLogoutTime();
            }
        });
    }
    async UserLogoutTime() {
        let CompanyCode = localStorage.getItem("Companycode");
        let euid = localStorage.getItem("Euid");
        const headers = {
            'Content-Type': 'application/json',
            'Euid': euid,
            'CompanyCode': CompanyCode,
            'BrowserType': deviceDetect().browserName,
            'BrowserVersion': deviceDetect().browserFullVersion
        };
        const formData = new FormData();
        Object.keys(headers).forEach((key) => {
            formData.append(key, headers[key]);
        });
        const result = navigator.sendBeacon('services/api/UserLogoutEntryForCreateCarrier', formData);
        localStorage.clear();
        if (result) {

            console.log('Beacon sent successfully');
        } else {
            console.log('Beacon failed to send');
        }
        setTimeout(
            function () {
                console.log("-------");
            }, 9000);
    }
    UserLogOut() {
        this.setState({ loading: true});
        let euid = localStorage.getItem("Euid");
        fetch('api/login/UserLogoutForCreateCarrier', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Euid": euid,
            },
            body: JSON.stringify({
                "BrowserType": deviceDetect().browserName,
                "BrowserVersion": deviceDetect().browserFullVersion
            })
        }).then(() => {
            localStorage.clear();
            localStorage.setItem('UserLogOutButton', 'true');
            window.location.href = "/CreateCarrierLogin";
        });
        window.location.href = "/CreateCarrierLogin";
    }
    handleEnableOfficeAccountSwitch(elem, state) {        
        this.setState({
            EnableOfficebyAccountNum: state
        });
    }
    handledeletebttn() {
        this.setState({ deleteAlert: false });
    }
    handleOpendeletebttn(e) {
        if (this.state.CheckedArr.length === 0) {
            if (this.state.markCheckbox === false) {
                this.toasterredfun2("No Items Selected");
            }
            else {
                $("html, body").animate({ scrollTop: 0 }, "slow");
                this.setState({ deleteAlert: true });
            }
        }
        else {
            $("html, body").animate({ scrollTop: 0 }, "slow");
            this.setState({ deleteAlert: true });
        }
    }
    handleClearIpAddress() {
        this.setState({
            rToIpAddress: false, rFromIpAddress: false, FromIpAddress: "",
            ToIpAddress: ""
        });
    }
    handleCheckbox(e, item, index) {
        this.setState({ [e.target.id]: e.target.checked });
        if (!e.target.checked) {
            this.setState({ markCheckbox: false });
        }
        if ($('.checkBoxClass:checked').length === $('.checkBoxClass').length) {
            this.setState({ markCheckbox: true });
        } else {
            this.setState({ markCheckbox: false });
        }
        let newArray = [...this.state.CheckedArr, e.target.value];
        //adding to new list
        if (e.target.checked) {
            this.setState({
                CheckedArr: newArray
            });
        }
        if (this.state.CheckedArr.includes(e.target.value)) {
            newArray = newArray.filter(ip => ip !== e.target.value);
            this.setState({
                CheckedArr: newArray
            });
        }
    }

    handleDeleteIpAddress(e) {
        if (this.state.markCheckbox) {
            this.setState({ IpAddressList: [] });
        } else {
            let allIpList = this.state.IpAddressList;
            let checkedIpList = this.state.CheckedArr;
            for (const checkedIp of checkedIpList) {
                const index = allIpList.indexOf(checkedIp);
                if (index !== -1) {
                    allIpList.splice(index, 1);
                } else {
                    return;
                }
            }
            this.setState({ IpAddressList: allIpList });
        }
        this.setState({ CheckedArr: [] });
        $(".checkBoxClass").prop('checked', false);
        this.setState({ markCheckbox: false });
        this.toasterfun2("IP Addresses has been Deleted Successfully.");
        this.setState({ deleteAlert: false });
    }

    handleCheck(val) {
        return this.state.IpAddressList.some(item => val === item);
    }
    handleAddIpAddress(e) {
        let currentComponent = this;
        let isvalid = true;
        let updatedList = Object.assign([], this.state.IpAddressList)
        if (this.state.ToIpAddress === "") {
            this.setState({ rToIpAddress: true });
            isvalid = false;
        }
        if (this.state.FromIpAddress.length !== 0 || this.state.FromIpAddress !== "") {
            if (!this.ValidateIPaddress(this.state.FromIpAddress)) {
                this.setState({ rFromIpAddress: true });
                this.toasterredfun2("Invalid IpAddress");
                isvalid = false;
            }
        }
        if (this.state.ToIpAddress.length !== 0 || this.state.ToIpAddress !== "") {
            if (!this.ValidateIPaddress(this.state.ToIpAddress)) {
                this.setState({ rToIpAddress: true });
                this.toasterredfun2("Invalid IpAddress");
                isvalid = false;
            }
        }
        if (this.state.FromIpAddress === this.state.ToIpAddress) {
            this.toasterredfun2("Invalid IpAddress");
            isvalid = false;
        }
        if ((this.state.ToIpAddress !== "") && (this.state.FromIpAddress === "")) {
            let to = this.state.ToIpAddress;
            if (this.ValidateIPaddress(to)) {
                if (!this.handleCheck(to)) {
                    let validtoIpAddress = to;
                    updatedList.push(validtoIpAddress);
                    currentComponent.setState({
                        IpAddressList: updatedList.sort((a, b) => {
                            const num1 = Number(a.split(".").map((num) => (`000${num}`).slice(-3)).join(""));
                            const num2 = Number(b.split(".").map((num) => (`000${num}`).slice(-3)).join(""));
                            return num1 - num2;
                        })
                    });
                    this.toasterfun2("IP Address have been added successfully.");
                    this.handleClearIpAddress();
                    return;
                } else {
                    this.toasterredfun2("ERROR – IP Address(es) already is listed in the Current Public IP Addresses.Please check and try again");
                    isvalid = false;
                    this.handleClearIpAddress();
                }

            } else {
                this.setState({ rToIpAddress: true, rFromIpAddress: false });
                this.toasterredfun2("Invalid IpAddress");
                isvalid = false;
            }

        }
        if ((this.ValidateIPaddress(this.state.ToIpAddress) && this.ValidateIPaddress(this.state.FromIpAddress))) {
            let startIpArray = this.state.FromIpAddress.split('.');
            let endIpArray = this.state.ToIpAddress.split('.');
            let FromIpArray = startIpArray[0] + "." + startIpArray[1] + "." + startIpArray[2];
            let EndIpArray = endIpArray[0] + "." + endIpArray[1] + "." + endIpArray[2];
            if (FromIpArray !== EndIpArray || Number(startIpArray[3]) > Number(endIpArray[3])) {
                this.setState({ rToIpAddress: true });
                this.setState({ rFromIpAddress: true });
                this.toasterredfun2("Invalid IpAddress");
                isvalid = false;
            }
        }
        if (isvalid) {
            if (this.handleCheck(this.state.FromIpAddress) && this.handleCheck(this.state.ToIpAddress)) {
                this.AddIpAddressToList();
                this.toasterredfun2("ERROR – IP Address(es) already is listed in the Current Public IP Addresses.Please check and try again");
                this.handleClearIpAddress();
            } else {
                this.AddIpAddressToList();
                this.toasterfun2("IP Addresses have been added successfully.");
                this.handleClearIpAddress();
            }
        }

    }
    AddIpAddressToList() {
        let updatedList = Object.assign([], this.state.IpAddressList)
        let startIpArray = this.state.FromIpAddress.split('.');
        let endIpArray = this.state.ToIpAddress.split('.');
        let start = Number(startIpArray[3]);
        let end = Number(endIpArray[3]);
        if (start != end) {
            for (let i = start; i <= end; i++) {
                let ip = i;
                let ipAddress = startIpArray[0] + "." + startIpArray[1] + "." + startIpArray[2] + "." + ip;
                if (!this.handleCheck(ipAddress)) {
                    updatedList.push(ipAddress);
                }
            }
            this.setState({
                IpAddressList: updatedList.sort((a, b) => {
                    const num1 = Number(a.split(".").map((num) => (`000${num}`).slice(-3)).join(""));
                    const num2 = Number(b.split(".").map((num) => (`000${num}`).slice(-3)).join(""));
                    return num1 - num2;
                })
            });
        }
        else {
            let ToipAddress = this.state.ToIpAddress;
            if (!this.handleCheck(ToipAddress)) {
                updatedList.push(ToipAddress);
            }
            this.setState({
                IpAddressList: updatedList.sort((a, b) => {
                    const num1 = Number(a.split(".").map((num) => (`000${num}`).slice(-3)).join(""));
                    const num2 = Number(b.split(".").map((num) => (`000${num}`).slice(-3)).join(""));
                    return num1 - num2;
                })
            });
        }
    }

    ValidateIPaddress(e) {
        return /^(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)$/.test(e);
    }
    selectAllCheckboxes(e, state) {
        let check = e.target.checked;
        if (check) {
            $(".checkBoxClass").prop('checked', true);
            this.setState({ markCheckbox: true });
            let allIpList = this.state.IpAddressList;
            this.setState({ CheckedArr: allIpList });
        } else {
            $(".checkBoxClass").prop('checked', false);
            this.setState({ markCheckbox: false });
        }
    }
    testNumericForIpAddressLength(e) {

        if ((/[1-2]/, /\d/, /\d/, '.', /[1-2]/, /\d/, /\d/, '.', /[1-2]/, /\d/, /\d/, '.', /[1-2]/, /\d/, /\d/.test(e))) {
            return /^[.0-9]*$/.test(e);
        }
        else {
            return false;
        }
    }
    handleFromIpAddressChange(e) {
        this.setState({ rFromIpAddress: false });
        if (this.ValidateIPaddress(e.target.value)) {
            this.setState({ FromIpAddress: e.target.value });
        }
        if (e.target.value.length !== 0) {
            if (this.testNumericForIpAddressLength(e.target.value)) {
                this.setState({ FromIpAddress: e.target.value });
            }
        } else {
            this.setState({ FromIpAddress: e.target.value });
        }
    }

    handleToIpAddressChange(e) {
        this.setState({ rToIpAddress: false });
        if (this.ValidateIPaddress(e.target.value)) {
            this.setState({ ToIpAddress: e.target.value });
        }
        if (e.target.value.length !== 0) {
            if (this.testNumericForIpAddressLength(e.target.value)) {
                this.setState({ ToIpAddress: e.target.value });
            }
        } else {
            this.setState({ ToIpAddress: e.target.value });
        }
    }
    
    handlePassNeverExpires(e) {
        this.setState({
            IsPassNeverExpires: e.target.checked
        });
        let isChecked = e.target.checked;
        if (isChecked) {
            this.setState({ PassNeverExpires: true });
            this.setState({ rpassUpdateDays: false });
        }
        else {
            this.setState({ PassNeverExpires: false });
            this.setState({ rpassUpdateDays: false });
        }
    } 
    handleAPSChecked(e) {
        this.setState({
            IsAPSChecked: e.target.checked
        });
        let isChecked = e.target.checked;
        if (isChecked) {
            this.setState({ APSCheckbox: true });
            this.setState({ ShowAPSDiv: true });
        }
        else {
            this.setState({ APSCheckbox: false });
            this.setState({ ShowAPSDiv: false });
            this.setState({ CropNumber: "" });
        }
    }
    handleSummaryChecked(e) {
        this.setState({
            IsSummaryChecked: e.target.checked
        });
        let isChecked = e.target.checked;
        if (isChecked) {
            this.setState({ SummaryCheckbox: true });
            this.setState({ ShowSummaryDiv: true });
        }
        else {
            this.setState({ SummaryCheckbox: false });
            this.setState({ ShowSummaryDiv: false });
            this.setState({ SummaryCropNumber: "" });
        }
    }
    handleInactiveUser(e) {
        this.setState({
            IsNeverInactiveUser: e.target.checked
        });
        let isInactiveUser = e.target.checked;
        if (isInactiveUser) {
            this.setState({ NeverInactiveUser: true });
            this.setState({ rInactiveDays: false });
        }
        else {
            this.setState({ NeverInactiveUser: false });
            this.setState({ rInactiveDays: false });
        }
    }

    handlePassUpdateDaysChange(e) {
        this.setState({ rpassUpdateDays: false });
        if (e.target.value.length !== 0) {
            if (this.testNumericForThreeDigitTokenLength(e.target.value)) {
                this.setState({ DaysForEnforcePass: e.target.value });
            }
        } else {
            this.setState({ DaysForEnforcePass: e.target.value });
        }
    }
    handleRemPasswordDaysChange(e) {
        this.setState({ rRemPasswordCount: false });
        if (e.target.value.length !== 0) {
            if (this.testNumericForTwoDigitTokenLength(e.target.value)) {
                this.setState({ RememberPassCount: e.target.value });
            }
        } else {
            this.setState({ RememberPassCount: e.target.value });
        }
    }
    handleInactiveDaysChange(e) {
        this.setState({ rInactiveDays: false });
        if (e.target.value.length !== 0) {
            if (this.testNumericForThreeDigitTokenLength(e.target.value)) {
                this.setState({ InactiveDays: e.target.value });
            }
        } else {
            this.setState({ InactiveDays: e.target.value });
        }
    }
    testNumericForTwoDigitTokenLength(e) {
        return /^\d{0,2}$/g.test(e);
    }
    testNumericForThreeDigitTokenLength(e) {
        return /^\d{0,3}$/g.test(e);
    }
    handleIpAddressChange(elem, state) {
        this.setState({
            ipAddressChange: state, IpAddressListing: state
        });
    }
    handleallowDefaultOfficeforClientAdminUsers(e) {
        this.setState({ AllowDefaultOfficeCA: e.target.value });
    }
    handleRetentionPeriodDaysChange(e) {
        this.setState({ rRetentionPeriodDays: false });
        if (e.target.value.length !== 0) {
            if (this.testNumericForTokenLength(e.target.value)) {
                this.setState({ RetentionPeriodDays: e.target.value });
            }
        } else {
            this.setState({ RetentionPeriodDays: e.target.value });
        }
    }
    handleProductionPurgeDaysChange(e) {
        this.setState({ rProductionPurgeDays: false });
        if (e.target.value.length !== 0) {
            if (this.testNumericForTokenLength(e.target.value)) {
                this.setState({ ProductionPurgeDays: e.target.value });
            }
        } else {
            this.setState({ ProductionPurgeDays: e.target.value });
        }
    }
    handleOfflineArchivePurgeDaysChange(e) {
        this.setState({ rOfflineArchivePurgeDays: false });
        if (e.target.value.length !== 0) {
            if (this.testNumericForTokenLength(e.target.value)) {
                this.setState({ OfflineArchivePurgeDays: e.target.value });
            }
        } else {
            this.setState({ OfflineArchivePurgeDays: e.target.value });
        }
    }
    handleEnableSSOConnectionSwitch(elem, state) {
        if (state === true) {
            this.setState({ ipAddressChange: false, ipSwitchGrey: true, rRemPasswordCount: false });
        }
        this.setState({
            EnableSSOConnection: state, vIdentifier: state, PassNeverExpires: state, RememberPassword: state, NeverInactiveUser: state, greycheckbox: state,
            IpAddressListing: false, ipSwitchGrey: state

        });
    }
    handleIdentifierChange(e) {
        if (e.target.value.length != 0) {
            this.setState({ rIdentifier: false });
        }
        this.setState({ Identifier: e.target.value })

    }
    handleallowViewResultsChange(e) {
        this.setState({ allowViewResults: e.target.value });
    }

    handleallowOverrideAccessChange(e) {
        this.setState({ allowOverrideAccess: e.target.value });
    }

    handleallowPlaceOrderChange(e) {
        this.setState({ allowPlaceOrder: e.target.value });
    }

    handleallowViewNotesChange(e) {
        this.setState({ allowViewNotes: e.target.value });
    }

    handleallowViewAuthChange(e) {
        this.setState({ allowViewAuth: e.target.value });
    }
    handleallowViewStatusChange(e) {
        this.setState({ allowViewStatus: e.target.value });
    }
    handleallowCarrierLevelESRChange(e) {
        this.setState({ allowExportSearchResults: e.target.value });
    }
    handleallowCarrierLevelPMAChange(e) {
        this.setState({ allowPerformanceMetrics: e.target.value });
    }

    handleallowCarrierLeverCSRMessagingChange(e) {
        this.setState({ AllowCSRMessaging: e.target.value });
    } 

    handleIsActiveChange(e) {
        if (e.target.value === "No") {
            this.setState({ orderIsRequiredSelectedValue: "No", orderSummaryIsRequiredSelectedValue: "No" });
        }
        this.setState({ orderIsActiveSelectedValue: e.target.value });
    }

    handleIsRequiredChange(e) {
        if (e.target.value === "Yes") {
            this.setState({ orderIsActiveSelectedValue: "Yes" });
        }
        this.setState({ orderIsRequiredSelectedValue: e.target.value });
    }

    handleSummaryIsRequiredChange(e) {
        if (e.target.value === "Yes") {
            this.setState({ orderIsActiveSelectedValue: "Yes" });
        }
        this.setState({ orderSummaryIsRequiredSelectedValue: e.target.value });
    }

    closePopup(e) {
        this.setState({ isFieldsEditModalopen: false });
    }
    testAlphaNumericwithSpaceAndSpecialForCustomFieldsName(e) {
        if (/^[-_.&#a-zA-Z0-9',\s/]+$/g.test(e)) {
            return !/\s{2,}/g.test(e);
        }
        else {
            return false;
        }
    }
    handleEnableMFASwitch(elem, state) {
        this.setState({
            EnableMFA: state
        });
    }
    handleMFATokenTypeChange(e) {
        this.setState({ MFATokenType: e.target.value });
    }
    handleMFATokenExpiryDaysChange(e) {
        this.setState({ MFATokenExpiryDays: e.target.value });
    }
    handleMFATokenExpiryMinChange(e) {
        this.setState({ MFATokenExpiryMin: e.target.value });
    }
    handleMFATokenGenTypeChange(e) {
        this.setState({ MFATokenGenType: e.target.value });
    }
    handleMFATokenLengthChange(e) {
        if (e.target.value.length !== 0) {
            if (this.testNumericForTokenLength(e.target.value)) {
                this.setState({ MFATokenLength: e.target.value });
            }
        } else {
            this.setState({ MFATokenLength: e.target.value });
        }

    }
    testNumericForTokenLength(e) {
        return /^\d+$/g.test(e);
    }
    handleMFAIpWithUserChange(e) {
        this.setState({
            MFAUserwithIP: e.target.value
        });
    }

    handleCustomFieldNameChange(e) {
        this.setState({ customField: false });
        if (e.target.value.trim().length === 0 && e.target.value.length <= 35) {
            this.setState({ customFieldName: e.target.value });
        }
        else if (this.testAlphaNumericwithSpaceAndSpecialForCustomFieldsName(e.target.value) && e.target.value.length <= 35) {
            if (e.target.value.trim().length <= 50) {
                this.setState({ customFieldName: e.target.value });
            }
        }
    }

    GetRequiredFields2_ForGrid(SelectedSectionId) {
        if (SelectedSectionId == "1") {
            this.setState({ RequiredFieldsSec1: this.state.RequiredFieldsList.filter((item) => { return item.sectionId == SelectedSectionId; }) });
        }
        else if (SelectedSectionId == "4") {
            this.setState({ RequiredFieldsSec4: this.state.RequiredFieldsList.filter((item) => { return item.sectionId == SelectedSectionId; }) });
        }
        else if (SelectedSectionId == "5") {
            this.setState({ RequiredFieldsSec5: this.state.RequiredFieldsList.filter((item) => { return item.sectionId == SelectedSectionId; }) });
        }
        else if (SelectedSectionId == "6") {
            this.setState({ RequiredFieldsSec6: this.state.RequiredFieldsList.filter((item) => { return item.sectionId == SelectedSectionId; }) });
        }
        else if (SelectedSectionId == "7") {
            this.setState({ RequiredFieldsSec7: this.state.RequiredFieldsList.filter((item) => { return item.sectionId == SelectedSectionId; }) });
        }
        else if (SelectedSectionId == "13") {
            this.setState({ RequiredFieldsSec13: this.state.RequiredFieldsList.filter((item) => { return item.sectionId == SelectedSectionId; }) });
        }
        else if (SelectedSectionId == "14") {
            this.setState({ RequiredFieldsSec14: this.state.RequiredFieldsList.filter((item) => { return item.sectionId == SelectedSectionId; }) });
        }
    }  

    ShowCarrierManagerFields = () => {
        this.setState({ ShowFieldSetup: false, CarrierManagerFields: true, PatientInfoFields: false, AttorneyInfoFields: false, AdjusterInfoFields: false, FacilityInfoFields: false, AgentInfoFields: false, UnderwriterFields: false });
        this.GetRequiredFields2_ForGrid(6);
    };

    ShowAgentInfoFields = () => {
        this.setState({ ShowFieldSetup: false, CarrierManagerFields: false, PatientInfoFields: false, AttorneyInfoFields: false, AdjusterInfoFields: false, FacilityInfoFields: false, AgentInfoFields: true, UnderwriterFields: false });
        this.GetRequiredFields2_ForGrid(4);
    };

    ShowFacilityInfoFields = () => {
        this.setState({ ShowFieldSetup: false, CarrierManagerFields: false, PatientInfoFields: false, AttorneyInfoFields: false, AdjusterInfoFields: false, FacilityInfoFields: true, AgentInfoFields: false, UnderwriterFields: false });
        this.GetRequiredFields2_ForGrid(5);
    };

    ShowAdjusterInfoFields = () => {
        this.setState({ ShowFieldSetup: false, CarrierManagerFields: false, PatientInfoFields: false, AttorneyInfoFields: false, AdjusterInfoFields: true, FacilityInfoFields: false, AgentInfoFields: false, UnderwriterFields: false });
        this.GetRequiredFields2_ForGrid(13);
    };

    ShowAttorneyInfoFields = () => {
        this.setState({ ShowFieldSetup: false, CarrierManagerFields: false, PatientInfoFields: false, AttorneyInfoFields: true, AdjusterInfoFields: false, FacilityInfoFields: false, AgentInfoFields: false, UnderwriterFields: false });
        this.GetRequiredFields2_ForGrid(7);
    };

    ShowPatientInfoFields = () => {
        this.setState({ ShowFieldSetup: false, CarrierManagerFields: false, PatientInfoFields: true, AttorneyInfoFields: false, AdjusterInfoFields: false, FacilityInfoFields: false, AgentInfoFields: false, UnderwriterFields: false });
        this.GetRequiredFields2_ForGrid(1);
    };

    ShowUnderwriterFields = () => {
        this.setState({ ShowFieldSetup: false, CarrierManagerFields: false, PatientInfoFields: false, AttorneyInfoFields: false, AdjusterInfoFields: false, FacilityInfoFields: false, AgentInfoFields: false, UnderwriterFields: true });
        this.GetRequiredFields2_ForGrid(14);
    };

    handleUpdateFieldInformation_New() {
        let isvalid = true;
        let currentComponent = this;
        if (this.state.customFieldName == null || this.state.customFieldName.trim().length == 0) {
            this.setState({ customField: true });
            isvalid = false;
        }
        else {
            this.setState({ customField: false });
        }

        if (isvalid) {
            this.setState({ loading: true });        
            let CustomFieldName = currentComponent.state.customFieldName;
            let FieldId = currentComponent.state.fieldId;
            let IsActive = currentComponent.state.orderIsActiveSelectedValue;
            let IsRequired = currentComponent.state.orderIsRequiredSelectedValue;
            let SummaryIsRequired = currentComponent.state.orderSummaryIsRequiredSelectedValue;
            for (const field of currentComponent.state.RequiredFieldsList) {
                if (field.id == FieldId) {
                    field.customFieldName = CustomFieldName;
                    field.isActive = IsActive === "Yes";
                    field.isRequired = IsRequired;
                    field.summaryIsRequired = SummaryIsRequired === "Yes";
                }
            }
            currentComponent.setState({ isFieldsEditModalopen: false });
            currentComponent.setState({ loading: false });
        }
    }
    handleUpdateFieldInformation() {
        let isvalid = true;
        let currentComponent = this;
        if (this.state.customFieldName == null || this.state.customFieldName.trim().length == 0) {
            this.setState({ customField: true });
            isvalid = false;
        }
        else {
            this.setState({ customField: false });
        }

        if (isvalid) {
            this.setState({ loading: true });
            let SelectedCompanyType = localStorage.getItem("Companytyp");
            Api.Post('api/login/UpdateFieldInformation', {
                "CompanyType": SelectedCompanyType, "CustomFieldName": currentComponent.state.customFieldName, "IsActive": currentComponent.state.orderIsActiveSelectedValue,
                "FieldId": currentComponent.state.fieldId, "FieldType": currentComponent.state.fieldType, "IsRequired": currentComponent.state.orderIsRequiredSelectedValue, "FromCreatecarrier":true, "SummaryIsRequired": currentComponent.state.orderSummaryIsRequiredSelectedValue  
            }, currentComponent).then(function (result) {
                if (result.statusMessage == "Success") {
                    currentComponent.setState({ isFieldsEditModalopen: false });
                    currentComponent.setState({ loading: false });
                    currentComponent.setState({ RequiredFieldsList: result.requiredFields });
                    currentComponent.toasterfun2("Field has been updated successfully.");
                }
                else {
                    currentComponent.toasterredfun2(result.statusMessage);
                }
                currentComponent.setState({ loading: false });

            }).catch(function (error) {
                currentComponent.setState({ loading: false });
                if (error == "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun2("Check your internet connection.");
                }
                else {
                    currentComponent.toasterredfun2(error);
                }
                currentComponent.setState({ loading: false });
            });
        }
    }

    onClickopenFieldEditPopup = (rowData) => {
        this.setState({ isFieldsEditModalopen: true });
        this.setState({ originalFieldName: rowData.originalFieldName, customFieldName: rowData.customFieldName, orderIsActiveSelectedValue: rowData.isActive ? "Yes" : "No", orderIsRequiredSelectedValue: rowData.isRequired ? "Yes" : "No", fieldId: rowData.id, isDefault: rowData.isDefault, orderSummaryIsRequiredSelectedValue: rowData.summaryIsRequired ? "Yes" : "No" })
    }

    componentWillMount() {
        localStorage.clear();
        this.GetCNDDetails();
        this.setState({ ShowFieldSetup: false, CarrierManagerFields: false, PatientInfoFields: false, AttorneyInfoFields: false, AdjusterInfoFields: false, FacilityInfoFields: false, AgentInfoFields: false, UnderwriterFields: false });

    }

    ShowHideFields() {
        let CompanyType = localStorage.getItem("CompanyType");
        if (CompanyType == "LIFE") {
            this.setState({ CompanyType: localStorage.getItem("CompanyType") });
        }
        if (CompanyType == "LEGAL") {
            this.setState({ CompanyType: localStorage.getItem("CompanyType") });
        }
    }

    GetRequiredFields(SelectedCompanyType) {
        let currentComponent = this;
        currentComponent.setState({ loading: true, CustomFieldName: "", isActive: false, IsRequired: false });
        Api.Post('api/login/GetRequiredFieldsCarrierDetails', { "CompanyType": SelectedCompanyType, "FromCreatecarrier": true }, currentComponent).then(function (result) {
            if (result.statusCode == 401) {
                currentComponent.toasterredfun("You are not eligible to Place or Edit Order");
            }
            else {
                currentComponent.setState({ RequiredFieldsList: result.requiredFields });
                currentComponent.GetRequiredFields2_ForGrid(1);
            }
        }).catch(function (error) {
            currentComponent.setState({ loading: false });
            if (error == "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }

    handleClearDetails(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({
            CropNumber: "", CarrierName: "", Carrier: "", ShowFieldSetup: false,
            Address: "", City: "", UCity: "", State: "", ZipCode: "",
            MainContactName: "", MainContactEmail: "", MainContactPhone: "",
            AlternateContactName: "", AlternateContactEmail: "", AlternateContactPhone: "",
            CustomerServiceContactName: "", CustomerServiceContactEmail: "", CustomerServiceContactPhone: "",
            CompanyType: "", CompanyCode: "",
            OOName: "", OOAccountNo: "", OOCode: "", OOEmail: "", OOPhone: "",
            rOOName: false, rOOAccountNo: false, rOOCode: false, rOOEmail: false, rOOPhone: false,
            rCropNumber: false, rCarrierName: false,
            rAddress: false, rCity: false, rUCity: false, rState: false, rZipCode: false,
            rMainContactName: false, rMainContactEmail: false, rMainContactPhone: false,
            rAlternateContactName: false, rAlternateContactEmail: false, rAlternateContactPhone: false,
            rCompanyType: false, rCompanyCode: false, toasterCla: false, toasterredCla: false, toaster: "",
            FirstName: "", LastName: "", UEmail: "", UPhone: "", UAddress: "", UState: "", UZip: "", AgentId: "",
            rFirstName: false, rLastName: false, rUEmail: false, rUPhone: false, rUAddress: false, rUState: false, rUZip: false, rAgentId: false,
            Summarization: false, OHB: "", rOHB: false, RHB: "", rRHB: false, EnableSSOConnection: false, RetentionPeriodDays: "120", OfflineArchivePurgeDays: "365", ProductionPurgeDays: "730",
            rRetentionPeriodDays: false, rOfflineArchivePurgeDays: false, rProductionPurgeDays: false, rpassUpdateDays: false,
            rRemPasswordCount: false,
            rInactiveDays: false,rCustomerServiceContactName: false, rCustomerServiceContactEmail: false, rCustomerServiceContactPhone: false, rSK: false, CustomerServiceContactNameforSummary: "", rCustomerServiceContactNameforSummary: false, CustomerServiceContactEmailforSummary: "", rCustomerServiceContactEmailforSummary: false, rCustomerServiceContactPhoneforSummary: false, CustomerServiceContactPhoneforSummary: "", APSCheckbox: false, SummaryCheckbox: false, IsAPSChecked: false, IsSummaryChecked: false, ShowAPSDiv: false, ShowSummaryDiv: false, SummaryCropNumber: "", rSummaryCropNumber: false, SK: ""
        });
    }
    handleFirstNameChange(e) {
        this.setState({ rFirstName: false });
        if (e.target.value.length === 0 || this.testAlphaNumericwithSpaceForName(e.target.value)) {
            this.setState({ FirstName: e.target.value });
        }

    }
    handleSKChange(e) {
        this.setState({ SK: e.target.value, rSK: false })
    }
    handleLastNameChange(e) {
        this.setState({ rLastName: false });
        if (e.target.value.length === 0 || this.testAlphaNumericwithSpaceForName(e.target.value)) {
            this.setState({ LastName: e.target.value });
        }
    }
    handleUEmailChange(e) {
        this.setState({ rUEmail: false });
        this.setState({ UEmail: e.target.value.replace(/ /g, '') });

    }
    handleUPhoneChange(e) {
        this.setState({ rUPhone: false });
        let phn = e.target.value.replace(/\D+/g, "");
        let curStart = this.ucpRef.current.selectionStart;
        if (phn.length === 0) {
            this.setState({ UPhone: "" });
        }
        if (/\d$/.test(phn)) {
            let phnArray = [...phn];
            if (phn.length === 1) {
                this.setState({ UPhone: "(" + phnArray[0] }, () => this.ucpRef.current.selectionStart = this.ucpRef.current.selectionEnd = curStart + 1);
            }
            if (phn.length === 2) {
                this.setState({ UPhone: "(" + phnArray[0] + phnArray[1] }, () => this.ucpRef.current.selectionStart = this.ucpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 3) {
                this.setState({ UPhone: "(" + phn }, () => this.ucpRef.current.selectionStart = this.ucpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 4) {
                this.setState({ UPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] }, () => this.ucpRef.current.selectionStart = this.ucpRef.current.selectionEnd = curStart + 2);
            }
            else if (phn.length === 5) {
                this.setState({ UPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] }, () => this.ucpRef.current.selectionStart = this.ucpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 6) {
                this.setState({ UPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] }, () => this.ucpRef.current.selectionStart = this.ucpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 7) {
                this.setState({ UPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] }, () => this.ucpRef.current.selectionStart = this.ucpRef.current.selectionEnd = curStart + 1);
            }
            else if (phn.length === 8) {
                this.setState({ UPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] }, () => this.ucpRef.current.selectionStart = this.ucpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 9) {
                this.setState({ UPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] }, () => this.ucpRef.current.selectionStart = this.ucpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 10) {
                this.setState({ UPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] + phnArray[9] }, () => this.ucpRef.current.selectionStart = this.ucpRef.current.selectionEnd = curStart);
            }
        }
    }
    handleUCityChange(e) {
        this.setState({ rUCity: false });
        if (e.target.value.length === 0 || this.testAlphawithSpaceforCity(e.target.value)) {
            this.setState({ UCity: e.target.value });
        }
    }
    handleUAddressChange(e) {
        this.setState({ UAddress: e.target.value, rUAddress: false });
    }
    handleUStateChange(e) {
        this.setState({ UState: e.target.value, rUState: false });
    }
    handleUZipChange(e) {
        this.setState({ rUZip: false });
        let zip = e.target.value.replace(/\D+/g, "");
        let curStart = this.uzipRef.current.selectionStart;
        if (zip.length === 0) {
            this.setState({ UZip: "" });
        }
        else if (/\d$/.test(zip)) {
            if (zip.length < 6) {
                this.setState({ UZip: zip }, () => this.uzipRef.current.selectionStart = this.uzipRef.current.selectionEnd = curStart);
            }
            else if (zip.length === 6) {
                this.setState({ UZip: zip.substring(0, 5) + "-" + zip.substring(5, 6) }, () => this.uzipRef.current.selectionStart = this.uzipRef.current.selectionEnd = curStart + 1);
            }
            else {
                this.setState({ UZip: zip.substring(0, 5) + "-" + zip.substring(5, 9) }, () => this.uzipRef.current.selectionStart = this.uzipRef.current.selectionEnd = curStart);
            }
        }

    }
    handleAgentIdChange(e) {
        this.setState({ rAgentId: false });
        if (e.target.value.length === 0 || this.testAlphaNumeric(e.target.value)) {
            this.setState({ AgentId: e.target.value });
        }
    }
    GetCNDDetails() {
        let currentComponent = this;
        currentComponent.setState({ Loading: true });
        fetch('api/login/GetCreateNewDatabaseDetails', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "UserId": "" })
        }).then(function (response) {
            if (!response.ok) {
                return response;
            }
            return response.json();
        }).then(function (result) {
            if (result.statusCode == 414) {
                currentComponent.toasterredfun(result.statusMessage);
            }
            else if (result.statusMessage == "User not authorized to access the page") {
                currentComponent.toasterredfun(result.statusMessage);
            }
            else if (result.statusMessage == "Success") {
                currentComponent.setState({ StatesList: result.states });
                currentComponent.setState({
                    CompanyTypeList: result.companyTypes,
                    MFATokenTypeList: result.mfaTokenTypeList, MFATokenExpiryDaysList: result.mfaTokenExpiryDaysList, MFATokenExpiryMinList: result.mfaTokenExpiryMinList, MFATokenGenTypeList: result.mfaTokenGenTypeList
                });
                currentComponent.setState({ Loading: false });
            }
            else {
                currentComponent.toasterredfun(result.statusMessage);
            }
        }).catch(function (error) {
            currentComponent.setState({ Loading: false });
            if (error == "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }
    handleCropNumberChange(e) {
        this.setState({ rCropNumber: false });
        if (e.target.value.length == 0 || this.testAlphaNumeric(e.target.value) ) {
            this.setState({ CropNumber: e.target.value });
        }       
    }
    handleSummaryCropNumberChange(e) {
        this.setState({ rSummaryCropNumber: false });
        if (e.target.value.length == 0 || this.testAlphaNumeric(e.target.value)) {
            this.setState({ SummaryCropNumber: e.target.value });
        }      
        }    
    handleCarrierNameChange(e) {
        this.setState({ rCarrierName: false });
        if (e.target.value.length === 0 || this.testAlphaNumericwithSpaceForCarrier(e.target.value)) {
            this.setState({ CarrierName: e.target.value });
        }
    }
    handleCompanyTypeChange(e) {
        this.setState({ rCompanyType: false });
        if (e.target.value.length === 0) {
            this.setState({ CompanyType: e.target.value, ShowFieldSetup: false, CarrierManagerFields: false, PatientInfoFields: false, AttorneyInfoFields: false, AdjusterInfoFields: false, FacilityInfoFields: false, AgentInfoFields: false });
        }
        else if (e.target.value === "LIFE" || e.target.value === "LEGAL") {
            this.GetRequiredFields(e.target.value);
            localStorage.setItem('Companytyp', e.target.value);
            this.setState({ ShowAfterCompanyTypeSelection: true, ShowFieldSetup: false, CompanyType: e.target.value, CarrierManagerFields: false, PatientInfoFields: true, AttorneyInfoFields: false, AdjusterInfoFields: false, FacilityInfoFields: false, AgentInfoFields: false });
        }
        else {
            this.setState({ ShowFieldSetup: false, ShowAfterCompanyTypeSelection: false, CompanyType: e.target.value, CarrierManagerFields: false, PatientInfoFields: false, AttorneyInfoFields: false, AdjusterInfoFields: false, FacilityInfoFields: false, AgentInfoFields: false });
        }
    }
    handleCompanyCodeChange(e) {
        this.setState({ rCompanyCode: false });
        this.setState({ rOOName: false });
        if (e.target.value.length === 0 || this.testAlphaNumeric(e.target.value)) {
            this.setState({ CompanyCode: e.target.value });
        }
    }
    handleAddressChange(e) {
        this.setState({ rAddress: false });
        this.setState({ Address: e.target.value });
    }
    handleCityChange(e) {
        this.setState({ rCity: false });
        if (e.target.value.length === 0 || this.testAlphawithSpaceforCity(e.target.value)) {
            this.setState({ City: e.target.value });
        }
    }
    handleStateChange(e) {
        this.setState({ rState: false });
        this.setState({ State: e.target.value });
    }
    toasterfun2(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterCla: false });
            }.bind(this), 5000);
    }
    toasterredfun2(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
            }.bind(this), 5000);
    }
    toasterredfun(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
                this.props.history.goBack();
            }.bind(this), 5000);
    }


    handleZipCodeChange(e) {
        this.setState({ rZipCode: false });
        let zip = e.target.value.replace(/\D+/g, "");
        let curStart = this.zipRef.current.selectionStart;
        if (zip.length === 0) {
            this.setState({ ZipCode: "" });
        }
        else if (/\d$/.test(zip)) {
            if (zip.length < 6) {
                this.setState({ ZipCode: zip }, () => this.zipRef.current.selectionStart = this.zipRef.current.selectionEnd = curStart);
            }
            else if (zip.length === 6) {
                this.setState({ ZipCode: zip.substring(0, 5) + "-" + zip.substring(5, 6) }, () => this.zipRef.current.selectionStart = this.zipRef.current.selectionEnd = curStart + 1);
            }
            else {
                this.setState({ ZipCode: zip.substring(0, 5) + "-" + zip.substring(5, 9) }, () => this.zipRef.current.selectionStart = this.zipRef.current.selectionEnd = curStart);
            }
        }

    }
    handleMainContactNameChange(e) {
        this.setState({ rMainContactName: false });
        if (e.target.value.length === 0 || this.testAlphaNumericwithSpaceForName(e.target.value)) {
            this.setState({ MainContactName: e.target.value });
        }
    }
    handleMainContactEmailChange(e) {
        this.setState({ rMainContactEmail: false });
        this.setState({ MainContactEmail: e.target.value.replace(/ /g, '') });  
    }
    handleMainContactPhoneChange(e) {
        this.setState({ rMainContactPhone: false });
        let phn = e.target.value.replace(/\D+/g, "");
        let curStart = this.mcpRef.current.selectionStart;
        if (phn.length === 0) {
            this.setState({ MainContactPhone: "" });
        }
        if (/\d$/.test(phn)) {
            let phnArray = [...phn];
            if (phn.length === 1) {
                this.setState({ MainContactPhone: "(" + phnArray[0] }, () => this.mcpRef.current.selectionStart = this.mcpRef.current.selectionEnd = curStart + 1);
            }
            if (phn.length === 2) {
                this.setState({ MainContactPhone: "(" + phnArray[0] + phnArray[1] }, () => this.mcpRef.current.selectionStart = this.mcpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 3) {
                this.setState({ MainContactPhone: "(" + phn }, () => this.mcpRef.current.selectionStart = this.mcpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 4) {
                this.setState({ MainContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] }, () => this.mcpRef.current.selectionStart = this.mcpRef.current.selectionEnd = curStart + 2);
            }
            else if (phn.length === 5) {
                this.setState({ MainContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] }, () => this.mcpRef.current.selectionStart = this.mcpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 6) {
                this.setState({ MainContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] }, () => this.mcpRef.current.selectionStart = this.mcpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 7) {
                this.setState({ MainContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] }, () => this.mcpRef.current.selectionStart = this.mcpRef.current.selectionEnd = curStart + 1);
            }
            else if (phn.length === 8) {
                this.setState({ MainContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] }, () => this.mcpRef.current.selectionStart = this.mcpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 9) {
                this.setState({ MainContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] }, () => this.mcpRef.current.selectionStart = this.mcpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 10) {
                this.setState({ MainContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] + phnArray[9] }, () => this.mcpRef.current.selectionStart = this.mcpRef.current.selectionEnd = curStart);
            }
        }
    }
    handleAlternateContactNameChange(e) {
        this.setState({ rAlternateContactName: false });
        if (e.target.value.length === 0 || this.testAlphaNumericwithSpaceForName(e.target.value)) {
            this.setState({ AlternateContactName: e.target.value });
        }
    }
    handleAlternateContactEmailChange(e) {
        this.setState({ rAlternateContactEmail: false });
        this.setState({ AlternateContactEmail: e.target.value.replace(/ /g, '') });
    }
    handleAlternateContactPhoneChange(e) {
        this.setState({ rAlternateContactPhone: false });
        let phn = e.target.value.replace(/\D+/g, "");
        let curStart = this.acpRef.current.selectionStart;
        if (phn.length === 0) {
            this.setState({ AlternateContactPhone: "" });
        }
        if (/\d$/.test(phn)) {
            let phnArray = [...phn];
            if (phn.length === 1) {
                this.setState({ AlternateContactPhone: "(" + phnArray[0] }, () => this.acpRef.current.selectionStart = this.acpRef.current.selectionEnd = curStart + 1);
            }
            if (phn.length === 2) {
                this.setState({ AlternateContactPhone: "(" + phnArray[0] + phnArray[1] }, () => this.acpRef.current.selectionStart = this.acpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 3) {
                this.setState({ AlternateContactPhone: "(" + phn }, () => this.acpRef.current.selectionStart = this.acpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 4) {
                this.setState({ AlternateContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] }, () => this.acpRef.current.selectionStart = this.acpRef.current.selectionEnd = curStart + 2);
            }
            else if (phn.length === 5) {
                this.setState({ AlternateContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] }, () => this.acpRef.current.selectionStart = this.acpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 6) {
                this.setState({ AlternateContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] }, () => this.acpRef.current.selectionStart = this.acpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 7) {
                this.setState({ AlternateContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] }, () => this.acpRef.current.selectionStart = this.acpRef.current.selectionEnd = curStart + 1);
            }
            else if (phn.length === 8) {
                this.setState({ AlternateContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] }, () => this.acpRef.current.selectionStart = this.acpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 9) {
                this.setState({ AlternateContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] }, () => this.acpRef.current.selectionStart = this.acpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 10) {
                this.setState({ AlternateContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] + phnArray[9] }, () => this.acpRef.current.selectionStart = this.acpRef.current.selectionEnd = curStart);
            }
        }
    }
    handleCustomerServiceContactNameChange(e) {
     
        this.setState({ rCustomerServiceContactName: false });
        if (e.target.value.length === 0 || this.testAlphaNumericwithSpaceForName(e.target.value)) {
            this.setState({ CustomerServiceContactName: e.target.value });
        }   
    }
    handleCustomerServiceContactNameChangeforSummary(e) {
        this.setState({ rCustomerServiceContactNameforSummary: false });
            if (e.target.value.length == 0 || this.testAlphaNumericwithSpaceForName(e.target.value)) {
            this.setState({ CustomerServiceContactNameforSummary: e.target.value });
        }
    }
    handleCustomerServiceContactEmailChange(e) {
        this.setState({ rCustomerServiceContactEmail: false });
        this.setState({ CustomerServiceContactEmail: e.target.value.replace(/ /g, '') });
    }
    handleCustomerServiceContactEmailChangeforSummary(e) {
        this.setState({ rCustomerServiceContactEmailforSummary: false });
        this.setState({ CustomerServiceContactEmailforSummary: e.target.value.replace(/ /g, '') });
    }

    handleCustomerServiceContactPhoneChange(e) {
        this.setState({ rCustomerServiceContactPhone: false });
        let phn = e.target.value.replace(/\D+/g, "");
        let curStart = this.ccpnRef.current.selectionStart;
        if (phn.length === 0) {
            this.setState({ CustomerServiceContactPhone: "" });
        }
        if (/\d$/.test(phn)) {
            let phnArray = [...phn];
            if (phn.length === 1) {
                this.setState({ CustomerServiceContactPhone: "(" + phnArray[0] }, () => this.ccpnRef.current.selectionStart = this.ccpnRef.current.selectionEnd = curStart + 1);
            }
            if (phn.length === 2) {
                this.setState({ CustomerServiceContactPhone: "(" + phnArray[0] + phnArray[1] }, () => this.ccpnRef.current.selectionStart = this.ccpnRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 3) {
                this.setState({ CustomerServiceContactPhone: "(" + phn }, () => this.ccpnRef.current.selectionStart = this.ccpnRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 4) {
                this.setState({ CustomerServiceContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] }, () => this.ccpnRef.current.selectionStart = this.ccpnRef.current.selectionEnd = curStart + 2);
            }
            else if (phn.length === 5) {
                this.setState({ CustomerServiceContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] }, () => this.ccpnRef.current.selectionStart = this.ccpnRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 6) {
                this.setState({ CustomerServiceContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] }, () => this.ccpnRef.current.selectionStart = this.ccpnRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 7) {
                this.setState({ CustomerServiceContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] }, () => this.ccpnRef.current.selectionStart = this.ccpnRef.current.selectionEnd = curStart + 1);
            }
            else if (phn.length === 8) {
                this.setState({ CustomerServiceContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] }, () => this.ccpnRef.current.selectionStart = this.ccpnRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 9) {
                this.setState({ CustomerServiceContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] }, () => this.ccpnRef.current.selectionStart = this.ccpnRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 10) {
                this.setState({ CustomerServiceContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] + phnArray[9] }, () => this.ccpnRef.current.selectionStart = this.ccpnRef.current.selectionEnd = curStart);
            }
        }
    }
    handleCustomerServiceContactPhoneChangeforSummary(e) {
        this.setState({ rCustomerServiceContactPhoneforSummary: false });
        let phn = e.target.value.replace(/\D+/g, "");
        let curStart = this.ccpnsRef.current.selectionStart;
        if (phn.length == 0) {
            this.setState({ CustomerServiceContactPhoneforSummary: "" });
        }
        if (/\d$/.test(phn)) {
            let phnArray = [...phn];
            if (phn.length == 1) {
                this.setState({ CustomerServiceContactPhoneforSummary: "(" + phnArray[0] }, () => this.ccpnsRef.current.selectionStart = this.ccpnsRef.current.selectionEnd = curStart + 1);
            }
            if (phn.length == 2) {
                this.setState({ CustomerServiceContactPhoneforSummary: "(" + phnArray[0] + phnArray[1] }, () => this.ccpnsRef.current.selectionStart = this.ccpnsRef.current.selectionEnd = curStart);
            }
            else if (phn.length == 3) {
                this.setState({ CustomerServiceContactPhoneforSummary: "(" + phn }, () => this.ccpnsRef.current.selectionStart = this.ccpnsRef.current.selectionEnd = curStart);
            }
            else if (phn.length == 4) {
                this.setState({ CustomerServiceContactPhoneforSummary: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] }, () => this.ccpnsRef.current.selectionStart = this.ccpnsRef.current.selectionEnd = curStart + 2);
            }
            else if (phn.length == 5) {
                this.setState({ CustomerServiceContactPhoneforSummary: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] }, () => this.ccpnsRef.current.selectionStart = this.ccpnsRef.current.selectionEnd = curStart);
            }
            else if (phn.length == 6) {
                this.setState({ CustomerServiceContactPhoneforSummary: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] }, () => this.ccpnsRef.current.selectionStart = this.ccpnsRef.current.selectionEnd = curStart);
            }
            else if (phn.length == 7) {
                this.setState({ CustomerServiceContactPhoneforSummary: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] }, () => this.ccpnsRef.current.selectionStart = this.ccpnsRef.current.selectionEnd = curStart + 1);
            }
            else if (phn.length == 8) {
                this.setState({ CustomerServiceContactPhoneforSummary: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] }, () => this.ccpnsRef.current.selectionStart = this.ccpnsRef.current.selectionEnd = curStart);
            }
            else if (phn.length == 9) {
                this.setState({ CustomerServiceContactPhoneforSummary: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] }, () => this.ccpnsRef.current.selectionStart = this.ccpnsRef.current.selectionEnd = curStart);
            }
            else if (phn.length == 10) {
                this.setState({ CustomerServiceContactPhoneforSummary: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] + phnArray[9] }, () => this.ccpnsRef.current.selectionStart = this.ccpnsRef.current.selectionEnd = curStart);
            }
        }
    }
    handleOONameChange(e) {
        this.setState({ rOOName: false });
        if (e.target.value.length === 0 || this.testAlphaNumericwithSpaceForCarrier(e.target.value)) {
            this.setState({ OOName: e.target.value });
        }
    }
    handleOOCodeChange(e) {
        this.setState({ rOOCode: false });
        if (e.target.value.length === 0 || this.testAlphaNumericWithHyphen(e.target.value)) {
            this.setState({ OOCode: e.target.value });
        }
    }
    testAlphaNumericWithHyphen(e) {
        return /^[a-zA-Z0-9-]+$/g.test(e);
    }
    handleOOEmailChange(e) {
        this.setState({ rOOEmail: false });
        this.setState({ OOEmail: e.target.value.replace(/ /g, '') });
    }
    handleOOPhoneChange(e) {

        this.setState({ rOOPhone: false });
        let phn = e.target.value.replace(/\D+/g, "");
        let curStart = this.ocpRef.current.selectionStart;
        if (phn.length == 0) {
            this.setState({ OOPhone: "" });
        }
        if (/\d$/.test(phn)) {
            let phnArray = [...phn];
            if (phn.length == 1) {
                this.setState({ OOPhone: "(" + phnArray[0] }, () => this.ocpRef.current.selectionStart = this.ocpRef.current.selectionEnd = curStart + 1);
            }
            if (phn.length == 2) {
                this.setState({ OOPhone: "(" + phnArray[0] + phnArray[1] }, () => this.ocpRef.current.selectionStart = this.ocpRef.current.selectionEnd = curStart);
            }
            else if (phn.length == 3) {
                this.setState({ OOPhone: "(" + phn }, () => this.ocpRef.current.selectionStart = this.ocpRef.current.selectionEnd = curStart);
            }
            else if (phn.length == 4) {
                this.setState({ OOPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] }, () => this.ocpRef.current.selectionStart = this.ocpRef.current.selectionEnd = curStart + 2);
            }
            else if (phn.length == 5) {
                this.setState({ OOPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] }, () => this.ocpRef.current.selectionStart = this.ocpRef.current.selectionEnd = curStart);
            }
            else if (phn.length == 6) {
                this.setState({ OOPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] }, () => this.ocpRef.current.selectionStart = this.ocpRef.current.selectionEnd = curStart);
            }
            else if (phn.length == 7) {
                this.setState({ OOPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] }, () => this.ocpRef.current.selectionStart = this.ocpRef.current.selectionEnd = curStart + 1);
            }
            else if (phn.length == 8) {
                this.setState({ OOPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] }, () => this.ocpRef.current.selectionStart = this.ocpRef.current.selectionEnd = curStart);
            }
            else if (phn.length == 9) {
                this.setState({ OOPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] }, () => this.ocpRef.current.selectionStart = this.ocpRef.current.selectionEnd = curStart);
            }
            else if (phn.length == 10) {
                this.setState({ OOPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] + phnArray[9] }, () => this.ocpRef.current.selectionStart = this.ocpRef.current.selectionEnd = curStart);
            }
        }
    }
    handleOOAccNumChange(e) {
        this.setState({ rOOAccountNo: false });
        if (e.target.value.length === 0 || this.testAlphaNumeric(e.target.value)) {
            this.setState({ OOAccountNo: e.target.value });
        }
    }
    handleSummarization(e) {
        this.setState({
            Summarization: e.target.checked
        });
    }
    handleOHBChange(e) {
        this.setState({ OHB: e.target.value, rOHB: false });
    }
    handleRHBChange(e) {
        this.setState({ RHB: e.target.value, rRHB: false });
    }
    handleSubmitCompanyDetails(e) {
        let CompanyId = 1, isvalid = true;
        if (this.state.SK == null || this.state.SK.length == 0) {
            isvalid = false;
            this.setState({ rSK: true });
        }
        if (this.state.CarrierName == null || this.state.CarrierName.length == 0) {
            isvalid = false;
            this.setState({ rCarrierName: true });
        }
        if (this.state.ShowAPSDiv) {
            if (this.state.CropNumber == null || this.state.CropNumber.length == 0) {
                isvalid = false;
                this.setState({ rCropNumber: true });
            }
        }
        if (!this.state.ShowAPSDiv && this.state.ShowSummaryDiv) {
            if (this.state.SummaryCropNumber == null || this.state.SummaryCropNumber.length == 0) {
                isvalid = false;
                this.setState({ rSummaryCropNumber: true });
            }
        }
        if (this.state.CompanyType == null || this.state.CompanyType.length == 0) {
            isvalid = false;
            this.setState({ rCompanyType: true });
        }
        if (this.state.CompanyCode == null || this.state.CompanyCode.length == 0) {
            isvalid = false;
            this.setState({ rCompanyCode: true });
        }
        if (this.state.City == null || this.state.City.length == 0) {
            isvalid = false;
            this.setState({ rCity: true });
        }
        if (this.state.State == null || this.state.State.length == 0 || this.state.State == "Select") {
            isvalid = false;
            this.setState({ rState: true });
        }     
        if (this.state.ShowAPSDiv) {
            if (this.state.OHB == null || this.state.OHB.length == 0 || this.state.OHB == "0") {
                isvalid = false;
                this.setState({ rOHB: true });
            }           
            if (this.state.RHB == null || this.state.RHB.length == 0 || this.state.RHB == "0") {
                isvalid = false;
                this.setState({ rRHB: true });
            }            
        }
         if (this.state.ZipCode === null || this.state.ZipCode.length === 0 || this.state.ZipCode.length < 5
            || (this.state.ZipCode.length > 5 && this.state.ZipCode.length < 10)
            || (this.state.ZipCode.length === 5 && !this.testZip(this.state.ZipCode))
            || (this.state.ZipCode.length === 10 && !this.testZip5(this.state.ZipCode))) {
            isvalid = false;
            this.setState({ rZipCode: true });
        }
        if (this.state.UZip === null || this.state.UZip.length === 0 || this.state.UZip.length < 5
            || (this.state.UZip.length > 5 && this.state.UZip.length < 10)
            || (this.state.UZip.length === 5 && !this.testZip(this.state.UZip))
            || (this.state.UZip.length === 10 && !this.testZip5(this.state.UZip))) {
            isvalid = false;
            this.setState({ rUZip: true });
        }
        if (this.state.MainContactEmail !== null && this.state.MainContactEmail.length !== 0) {
            if (this.state.rMainContactEmail) {
                isvalid = false;
                this.setState({ rMainContactEmail: true });
            }
        }
        if (this.state.MainContactPhone !== null && this.state.MainContactPhone.length !== 0) {
            if (!this.testPhone(this.state.MainContactPhone)) {
                isvalid = false;
                this.setState({ rMainContactPhone: true });
            }
        }
        if (this.state.AlternateContactEmail !== null && this.state.AlternateContactEmail.length !== 0) {
            if (this.state.rAlternateContactEmail) {
                isvalid = false;
                this.setState({ rAlternateContactEmail: true });
            }
        }
        if (this.state.AlternateContactPhone !== null && this.state.AlternateContactPhone.length !== 0) {
            if (!this.testPhone(this.state.AlternateContactPhone)) {
                isvalid = false;
                this.setState({ rAlternateContactPhone: true });
            }
        }
        if (this.state.ShowAPSDiv) {
            if (this.state.CustomerServiceContactName == null || this.state.CustomerServiceContactName.length == 0) {
                isvalid = false;
                this.setState({ rCustomerServiceContactName: true });
            }
            if (this.state.CustomerServiceContactEmail == null || this.state.CustomerServiceContactEmail.length == 0) {
                isvalid = false;
                this.setState({ rCustomerServiceContactEmail: true });
            }
            else if (this.state.CustomerServiceContactEmail != null && this.state.CustomerServiceContactEmail.length != 0) {
                if (this.state.rCustomerServiceContactEmail) {
                    isvalid = false;
                    this.setState({ rCustomerServiceContactEmail: true });
                }
            }         

            if (this.state.CustomerServiceContactPhone == null || this.state.CustomerServiceContactPhone.length == 0) {
                isvalid = false;
                this.setState({ rCustomerServiceContactPhone: true });
            }
            else if (this.state.CustomerServiceContactPhone != null && this.state.CustomerServiceContactPhone.length != 0) {
                if (!this.testPhone(this.state.CustomerServiceContactPhone)) {
                    isvalid = false;
                    this.setState({ rCustomerServiceContactPhone: true });
                }
            }
        }
        if (this.state.ShowSummaryDiv) {
            if (this.state.CustomerServiceContactNameforSummary == null || this.state.CustomerServiceContactNameforSummary.length == 0) {
                isvalid = false;
                this.setState({ rCustomerServiceContactNameforSummary: true });
            }
            if (this.state.CustomerServiceContactEmailforSummary == null || this.state.CustomerServiceContactEmailforSummary.length == 0) {
                isvalid = false;
                this.setState({ rCustomerServiceContactEmailforSummary: true });
            }
            else if (this.state.CustomerServiceContactEmailforSummary != null && this.state.CustomerServiceContactEmailforSummary.length != 0) {
                if (this.state.rCustomerServiceContactEmailforSummary) {
                    isvalid = false;
                    this.setState({ rCustomerServiceContactEmailforSummary: true });
                }
            }
            if (this.state.CustomerServiceContactPhoneforSummary == null || this.state.CustomerServiceContactPhoneforSummary.length == 0) {
                isvalid = false;
                this.setState({ rCustomerServiceContactPhoneforSummary: true });
            }
            else if (this.state.CustomerServiceContactPhoneforSummary != null && this.state.CustomerServiceContactPhoneforSummary.length != 0) {
                if (!this.testPhone(this.state.CustomerServiceContactPhoneforSummary)) {
                    isvalid = false;
                    this.setState({ rCustomerServiceContactPhoneforSummary: true });
                }
            }
        }
        if (this.state.OOEmail != null && this.state.OOEmail.length != 0) {
            if (this.state.rOOEmail) {
                isvalid = false;
                this.setState({ rOOEmail: true });
            }
        }
        if (this.state.OOPhone != null && this.state.OOPhone.length != 0) {
            if (!this.testPhone(this.state.OOPhone)) {
                isvalid = false;
                this.setState({ rOOPhone: true });
            }
        }
        if (this.state.UEmail == null || this.state.UEmail.length == 0) {
            isvalid = false;
            this.setState({ rUEmail: true });
        }
        else if (this.state.UEmail != null && this.state.UEmail.length != 0) {
            if (this.state.rUEmail) {
                isvalid = false;
                this.setState({ rUEmail: true });
            }
            else if (this.state.UEmail.toLowerCase() == "ordersubmission@enoahisolution.com") {
                isvalid = false;
                this.setState({ rUEmail: true });
                this.toasterredfun2("Please give another email address");
            }
        }
        if (this.state.UPhone == null || this.state.UPhone.length == 0) {
            isvalid = false;
            this.setState({ rUPhone: true });
        }
        else if (this.state.UPhone != null && this.state.UPhone.length != 0) {
            if (!this.testPhone(this.state.UPhone)) {
                isvalid = false;
                this.setState({ rUPhone: true });
            }
        }
        if (this.state.FirstName == null || this.state.FirstName.length == 0) {
            isvalid = false;
            this.setState({ rFirstName: true });
            }
        if (this.state.LastName == null || this.state.LastName.length == 0) {
            isvalid = false;
            this.setState({ rLastName: true });
        }
        if (this.state.OOName == null || this.state.OOName.length == 0) {
            isvalid = false;
            this.setState({ rOOName: true });
        }
        if (this.state.UCity == null || this.state.UCity.length == 0) {
            isvalid = false;
            this.setState({ rUCity: true });
        }
        if (this.state.UState == null || this.state.UState.length === 0 || this.state.UState === "Select") {
            isvalid = false;
            this.setState({ rUState: true });
        }
        if (this.state.EnableSSOConnection===true && (this.state.Identifier === null || this.state.Identifier.length === 0)) {
            isvalid = false;
            this.setState({ rIdentifier: true });
        }
        if (this.state.RetentionPeriodDays == null || this.state.RetentionPeriodDays.length == 0 || this.state.RetentionPeriodDays == "0") {
            this.setState({ rRetentionPeriodDays: true });
            this.toasterredfun2("The Retention Period Days should not be empty, and the value should be greater than 0.");
            return;
        }
        if (this.state.ProductionPurgeDays == null || this.state.ProductionPurgeDays.length == 0 || this.state.ProductionPurgeDays == "0") {
            this.setState({ rProductionPurgeDays: true });
            this.toasterredfun2("The Production Purge Days should not be empty, and the value should be greater than 0.");
            return;
        }
        if (this.state.OfflineArchivePurgeDays == null || this.state.OfflineArchivePurgeDays.length == 0 || this.state.OfflineArchivePurgeDays == "0") {
            this.setState({ rOfflineArchivePurgeDays: true });
            this.toasterredfun2("The Offline Archive Purge Days should not be empty, and the value should be greater than 0.");
            return;
        }
        let retentionPeriodDays = Number(this.state.RetentionPeriodDays);
        let productionPurgeDays = Number(this.state.ProductionPurgeDays);

        if (retentionPeriodDays == productionPurgeDays || retentionPeriodDays > productionPurgeDays) {
            this.setState({ rRetentionPeriodDays: true });
            this.toasterredfun2("Rentention Days must be lesser than Production Purge Days");
            return;
        }

        if (!this.state.EnableSSOConnection) {
            if (this.state.DaysForEnforcePass === null || this.state.DaysForEnforcePass.length === 0 && this.state.IsPassNeverExpires === false) {
                this.setState({ rpassUpdateDays: true });
                this.toasterredfun2("Enforce Password Days Should not be Empty");
                return;
            }
            else if (!this.state.IsPassNeverExpires) {
                if (this.state.DaysForEnforcePass > 120) {
                    this.setState({ rpassUpdateDays: true });
                    this.toasterredfun2("The Password Enforce Days value should not be greater than 120 ");
                    return;
                }
                if (this.state.DaysForEnforcePass == 0) {
                    this.setState({ rpassUpdateDays: true });
                    this.toasterredfun2("The Password Enforce Days value should  be greater than 0");
                    return;
                }
            }
        }

        if (!this.state.EnableSSOConnection) {
            if (this.state.RememberPassCount == null || this.state.RememberPassCount.length == 0 || this.state.RememberPassCount > 10) {
                this.setState({ rRemPasswordCount: true });
                this.toasterredfun2("The Remember Password Count should not be empty, and the value should not be greater than 10 ");
                return;
            }
            else if (this.state.RememberPassCount == 0) {
                this.setState({ rRemPasswordCount: true });
                this.toasterredfun2("The Remember Password Count should be greater than 0 ");
                return;
            }
        }

        if (!this.state.EnableSSOConnection) {
            if (this.state.InactiveDays == null || this.state.InactiveDays.length == 0 && !this.state.IsNeverInactiveUser) {
                this.setState({ rInactiveDays: true });
                this.toasterredfun2("Inactive Days Should not Empty");
                return;
            }
            else if (!this.state.IsNeverInactiveUser) {
                if (this.state.InactiveDays > 120) {
                    this.setState({ rInactiveDays: true });
                    this.toasterredfun2("An Inactive Days value should not be greater than 120 ");
                    return;
                }
                if (this.state.InactiveDays == 0) {
                    this.setState({ rInactiveDays: true });
                    this.toasterredfun2("An Inactive Days value should be greater than 0");
                    return;
                }
            }
        }
        if (!this.state.APSCheckbox && !this.state.SummaryCheckbox) {
            isvalid = false;
            this.toasterredfun2("Product must be selected");
        }
        if (isvalid) {
            this.setState({ Loading: true });
            let currentComponent = this;
            let data = {
                "UserId": currentComponent.state.SK,
                "CropNumber": currentComponent.state.CropNumber, "SummaryCropNumber": currentComponent.state.SummaryCropNumber, "Name": currentComponent.state.CarrierName, "Address": currentComponent.state.Address,
                "City": currentComponent.state.City, "CompanyId": CompanyId, "State": currentComponent.state.State, "Zip": currentComponent.state.ZipCode,
                "MainContactName": currentComponent.state.MainContactName, "MainContactEmail": currentComponent.state.MainContactEmail, "MainContactPhone": currentComponent.state.MainContactPhone,
                "AlternateContactName": currentComponent.state.AlternateContactName, "AlternateContactEmail": currentComponent.state.AlternateContactEmail, "AlternateContactPhone": currentComponent.state.AlternateContactPhone,
                "CustomerServiceContactName": currentComponent.state.CustomerServiceContactName, "CustomerServiceContactEmail": currentComponent.state.CustomerServiceContactEmail, "CustomerServiceContactPhone": currentComponent.state.CustomerServiceContactPhone,
                "CustomerServiceContactNameforSummary": currentComponent.state.CustomerServiceContactNameforSummary, "CustomerServiceContactEmailforSummary": currentComponent.state.CustomerServiceContactEmailforSummary, "CustomerServiceContactPhoneforSummary": currentComponent.state.CustomerServiceContactPhoneforSummary, "APSProduct": currentComponent.state.APSCheckbox, "SummaryProduct": currentComponent.state.SummaryCheckbox, "CompanyType": currentComponent.state.CompanyType, "CompanyCode": currentComponent.state.CompanyCode, "OfficeName": currentComponent.state.OOName, "OfficeCode": currentComponent.state.OOCode,
                "OfficeAccountNumber": currentComponent.state.OOAccountNo, "Email": currentComponent.state.OOEmail, "Phone": currentComponent.state.OOPhone,
                "FirstName": currentComponent.state.FirstName, "LastName": currentComponent.state.LastName, "UserEmail": currentComponent.state.UEmail, "UserPhone": currentComponent.state.UPhone,
                "UserAddress": currentComponent.state.UAddress, "UserState": currentComponent.state.UState, "UserCity": currentComponent.state.UCity, "AgentId": currentComponent.state.AgentId, "UserZip": currentComponent.state.UZip,
                "OHB": currentComponent.state.OHB, "RHB": currentComponent.state.RHB, "Summarization": currentComponent.state.Summarization,
                "RequiredFields": currentComponent.state.RequiredFieldsList, "IsEnableSSO": currentComponent.state.EnableSSOConnection, "Identifier": currentComponent.state.Identifier,
                "AllowViewResults": currentComponent.state.allowViewResults == "Yes",
                "AllowViewStatus": currentComponent.state.allowViewStatus == "Yes",
                "AllowViewNotes": currentComponent.state.allowViewNotes == "Yes",
                "AllowPlaceOrder": currentComponent.state.allowPlaceOrder == "Yes",
                "AllowOverrideAccess": currentComponent.state.allowOverrideAccess == "Yes",
                "AllowViewAuth": currentComponent.state.allowViewAuth == "Yes",
                "AllowExportSearchResults": currentComponent.state.allowExportSearchResults == "Yes",
                "AllowPerformanceMetrics": currentComponent.state.allowPerformanceMetrics == "Yes",
                "AllowCSRMessaging": currentComponent.state.AllowCSRMessaging == "Yes",
                "IsEnableMFA": currentComponent.state.EnableMFA,
                "MFATokenType": currentComponent.state.MFATokenType,
                "TokenExpiryDays": currentComponent.state.MFATokenExpiryDays,
                "TokenExpiryMin": currentComponent.state.MFATokenExpiryMin,
                "TokenGenType": currentComponent.state.MFATokenGenType,
                "TokenLength": currentComponent.state.MFATokenLength,
                "IsIpWithUser": currentComponent.state.MFAUserwithIP,
                "RetentionPeriodDays": currentComponent.state.RetentionPeriodDays,
                "OfflineArchivePurgeDays": currentComponent.state.OfflineArchivePurgeDays,
                "ProductionPurgeDays": currentComponent.state.ProductionPurgeDays,
                "AllowDefaultOfficeCA": currentComponent.state.AllowDefaultOfficeCA == "Yes" ? true : false,
                "DaysForEnforcePass": currentComponent.state.DaysForEnforcePass,
                "RememberPassCount": currentComponent.state.RememberPassCount,
                "InactiveDays": currentComponent.state.InactiveDays,
                "PasswordNeverExpires": currentComponent.state.IsPassNeverExpires,
                "NeverInactive": currentComponent.state.IsNeverInactiveUser,
                "IpAddress": currentComponent.state.IpAddressList,
                "IsEnableIP": currentComponent.state.ipAddressChange,
                "AssignOfficebyAccountNumb": currentComponent.state.EnableOfficebyAccountNum
            };

            Api.Post('api/login/CreateNewDatabase', data, currentComponent).then(function (result) {
                currentComponent.setState({ Loading: true });
                if (result.statusMessage == "Success") {
                    currentComponent.setState({
                        CropNumber: "", CarrierName: "", Carrier: "",
                        Address: "", City: "", UCity: "", State: "", ZipCode: "",
                        MainContactName: "", MainContactEmail: "", MainContactPhone: "",
                        AlternateContactName: "", AlternateContactEmail: "", AlternateContactPhone: "",
                        CustomerServiceContactName: "", CustomerServiceContactEmail: "", CustomerServiceContactPhone: "",
                        CompanyType: "", CompanyCode: "",
                        OOName: "", OOAccountNo: "", OOCode: "", OOEmail: "", OOPhone: "",
                        rOOName: false, rOOAccountNo: false, rOOCode: false, rOOEmail: false, rOOPhone: false,
                        rCropNumber: false, rCarrierName: false,
                        rAddress: false, rCity: false, rUCity: false, rState: false, rZipCode: false,
                        rMainContactName: false, rMainContactEmail: false, rMainContactPhone: false,
                        rAlternateContactName: false, rAlternateContactEmail: false, rAlternateContactPhone: false,
                        rCustomerServiceContactName: false, rCustomerServiceContactEmail: false, rCustomerServiceContactPhone: false,
                        rCompanyType: false, rCompanyCode: false, SK: "", rSK: false,
                        FirstName: "", LastName: "", UEmail: "", UPhone: "", UAddress: "", UState: "", UZip: "", AgentId: "",
                        rFirstName: false, rLastName: false, rUEmail: false, rUPhone: false, rUAddress: false, rUState: false, rUZip: false, rAgentId: false,
                        Summarization: false, OHB: "", rOHB: false, RHB: "", rRHB: false, Loading: false, RequiredFieldsList: [], RequiredFieldsSec1: [], RequiredFieldsSec3: [], RequiredFieldsSec4: [], RequiredFieldsSec5: [], RequiredFieldsSec6: [], RequiredFieldsSec7: [], RequiredFieldsSec13: [],
                        CarrierManagerFields: false, AgentInfoFields: false, AttorneyInfoFields: false, AdjusterInfoFields: false, PatientInfoFields: false,
                        FacilityInfoFields: false, Identifier: "", RetentionPeriodDays: "", OfflineArchivePurgeDays: "", ProductionPurgeDays: "", InactiveDays: "", RememberPassCount: "",
                        DaysForEnforcePass: "", IsPassNeverExpires: false, IsNeverInactiveUser: false, IpAddressList: [], rFromIpAddress: false,
                        FromIpAddress: "", rToIpAddress: false, ToIpAddress: "", IpList: [], RememberPassword: false,
                        ipSwitchGrey: false, ipAddressChange: false, IpAddressListing: false, PassNeverExpires: false, NeverInactiveUser: false, CustomerServiceContactNameforSummary: "", rCustomerServiceContactNameforSummary: false, CustomerServiceContactEmailforSummary: "", rCustomerServiceContactEmailforSummary: false, rCustomerServiceContactPhoneforSummary: false, CustomerServiceContactPhoneforSummary: "", APSCheckbox: false, SummaryCheckbox: false, IsAPSChecked: false, IsSummaryChecked: false, ShowAPSDiv: false, ShowSummaryDiv: false, SummaryCropNumber: "", rSummaryCropNumber: false
                        
                    });
                    currentComponent.toasterfun2("Client has been created successfully.");

                }
                else {
                    currentComponent.setState({ Loading: false });
                    currentComponent.toasterredfun2(result.statusMessage);
                }
                currentComponent.setState({ Loading: false });
            }).catch(function (error) {
                console.log(error);
                currentComponent.setState({ Loading: false });
                if (error == "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun2("Check your internet connection.");
                }
                else {
                    currentComponent.toasterredfun2(error);
                }
            });
        }
    }

    handleblurMainContactEmailChange(e) {
        let currentComponent = this;
        if (e.target.value.length != 0) {
            currentComponent.setState({ Loading: true });
            let data = { Email: e.target.value.replace(/ /g, '') };
            Api.Post(
                "services/api/IsValidEmailMailAddress/",
                data,
                currentComponent
            )
                .then(function (result) {

                    currentComponent.setState({ rMainContactEmail: !result });
                    currentComponent.setState({ Loading: false });

                })
                .catch(function (error) {
                    currentComponent.setState({ rMainContactEmail: false });
                    currentComponent.setState({ Loading: false });
                    if (error == "TypeError: Failed to fetch") {
                        currentComponent.toasterredfun2("Check your internet connection.");
                    } else {
                        currentComponent.toasterredfun2(error);
                    }
                });
        }
    }
    handleblurServiceContactEmailChange(e) {
        let currentComponent = this;
        if (e.target.value.length != 0) {
            currentComponent.setState({ Loading: true });
            let data = { Email: e.target.value.replace(/ /g, '') };
            Api.Post(
                "services/api/IsValidEmailMailAddress/",
                data,
                currentComponent
            )
                .then(function (result) {

                    currentComponent.setState({ rCustomerServiceContactEmail: !result });
                    currentComponent.setState({ Loading: false });

                })
                .catch(function (error) {
                    currentComponent.setState({ rCustomerServiceContactEmail: false });
                    currentComponent.setState({ Loading: false });
                    if (error == "TypeError: Failed to fetch") {
                        currentComponent.toasterredfun2("Check your internet connection.");
                    } else {
                        currentComponent.toasterredfun2(error);
                    }
                });
        }
    }
    handleblurCustomerServiceContactEmailChangeforSummary(e) {
        let currentComponent = this;
        if (e.target.value.length != 0) {
            currentComponent.setState({ Loading: true });
            let data = { Email: e.target.value.replace(/ /g, '') };
            Api.Post(
                "services/api/IsValidEmailMailAddress/",
                data,
                currentComponent
            )
                .then(function (result) {

                    currentComponent.setState({ rCustomerServiceContactEmailforSummary: !result });
                    currentComponent.setState({ Loading: false });

                })
                .catch(function (error) {
                    currentComponent.setState({ rCustomerServiceContactEmailforSummary: false });
                    currentComponent.setState({ Loading: false });
                    if (error == "TypeError: Failed to fetch") {
                        currentComponent.toasterredfun2("Check your internet connection.");
                    } else {
                        currentComponent.toasterredfun2(error);
                    }
                });
        }
    }
    handleblurEmailChange(e) {
        let currentComponent = this;
        if (e.target.value.length != 0) {
            currentComponent.setState({ Loading: true });
            let data = { Email: e.target.value.replace(/ /g, '') };
            Api.Post(
                "services/api/IsValidEmailMailAddress/",
                data,
                currentComponent
            )
                .then(function (result) {

                    currentComponent.setState({ rUEmail: !result });
                    currentComponent.setState({ Loading: false });

                })
                .catch(function (error) {
                    currentComponent.setState({ rUEmail: false });
                    currentComponent.setState({ Loading: false });
                    if (error == "TypeError: Failed to fetch") {
                        currentComponent.toasterredfun2("Check your internet connection.");
                    } else {
                        currentComponent.toasterredfun2(error);
                    }
                });
        }
    }
    handleblurOOEmailChange(e) {
        let currentComponent = this;
        if (e.target.value.length != 0) {
            currentComponent.setState({ Loading: true });
            let data = { Email: e.target.value.replace(/ /g, '') };
            Api.Post(
                "services/api/IsValidEmailMailAddress/",
                data,
                currentComponent
            )
                .then(function (result) {

                    currentComponent.setState({ rOOEmail: !result });
                    currentComponent.setState({ Loading: false });

                })
                .catch(function (error) {
                    currentComponent.setState({ rOOEmail: false });
                    currentComponent.setState({ Loading: false });
                    if (error == "TypeError: Failed to fetch") {
                        currentComponent.toasterredfun2("Check your internet connection.");
                    } else {
                        currentComponent.toasterredfun2(error);
                    }
                });
        }
    }
    handleblurAltContactEmailChange(e) {
        let currentComponent = this;
        if (e.target.value.length != 0) {
            currentComponent.setState({ Loading: true });
            let data = { Email: e.target.value.replace(/ /g, '') };
            Api.Post(
                "services/api/IsValidEmailMailAddress/",
                data,
                currentComponent
            )
                .then(function (result) {

                    currentComponent.setState({ rAlternateContactEmail: !result });
                    currentComponent.setState({ Loading: false });

                })
                .catch(function (error) {
                    currentComponent.setState({ rAlternateContactEmail: false });
                    currentComponent.setState({ Loading: false });
                    if (error == "TypeError: Failed to fetch") {
                        currentComponent.toasterredfun2("Check your internet connection.");
                    } else {
                        currentComponent.toasterredfun2(error);
                    }
                });
        }
    }

    testAlphaNumeric(e) {
        return /^[a-zA-Z0-9]+$/g.test(e);
    }
    testAlphawithSpace(e) {
        if (/^[a-zA-Z.\s]+$/g.test(e)) {
            return !/\s{2,}/g.test(e);
        }
        else {
            return false;
        }
    }
    testAlphawithSpaceforCity(e) {
        if (/^[a-zA-Z.\s'-]+$/g.test(e)) {
            if (/\s{2,}/g.test(e)) {
                return false;
            } else if (/-{2,}/g.test(e)) {
                return false;
            } else if (/'{2,}/g.test(e)) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
    testAlphaNumericwithSpaceForName(e) {
        if (/^[._&a-zA-Z0-9'-\s]+$/g.test(e)) {
            if (/\s{2,}/g.test(e)) {
                return false;
            }
            else if (/-{2,}/g.test(e)) {
                return false;
            }
            else if (/'{2,}/g.test(e)) {
                return false;
            }
            else if (/\.{2,}/g.test(e)) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return false;
        }
    }
    testAlphaNumericwithSpaceForCarrier(e) {
        if (/^[-_.&a-zA-Z0-9',\s]+$/g.test(e)) {
            if (/\s{2,}/g.test(e)) {
                return false;
            } else if (/\++/g.test(e)) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return false;
        }
    }  
    testAlphaNumericwithSpaceForEmail(e) {
        if (/^[a-zA-Z0-9@.]+$/g.test(e)) {
            if (/\s{2,}/g.test(e)) {
                return false;
            }
            else if (/#{2,}/g.test(e)) {
                return false;
            }
            else if (/@{2,}/g.test(e)) {
                return false;
            }
            else if (/[.]{2,}/g.test(e)) {
                return false;
            }
            else if (/_{2,}/g.test(e)) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return false;
        }
    }

    testAlphaNumericwithSpaceForEmailNew(e) {
        if (/^[a-zA-Z0-9,#!%$'&+*\-/=?^_`.{|}~@]+$/g.test(e)) {
            if (/\s{2,}/g.test(e) || /\}{2,}/g.test(e) || /\|{2,}/g.test(e) || /={2,}/g.test(e) || /,{2,}/g.test(e) || /[\\]{2,}/g.test(e) || /!{2,}/g.test(e) || /-{2,}/g.test(e) || /[{]{2,}/g.test(e) || /#{2,}/g.test(e) || /@{2,}/g.test(e) || /[.]{2,}/g.test(e) || /_{2,}/g.test(e) || /&{2,}/g.test(e) || /[$]{2,}/g.test(e) || /%{2,}/g.test(e) || /[*]{2,}/g.test(e) || /\^{2,}/g.test(e)) {
                return false;
            }
            else {
                let str = e;
                let a = str.indexOf(".");
                if (a === 0) {
                    return false;
                }
                let b = str.indexOf("#");
                if (b === 0) {
                    return false;
                }
                let c = str.indexOf("!");
                if (c === 0) {
                    return false;
                }
                let d = str.indexOf("%");
                if (d === 0) {
                    return false;
                }
                let z = str.indexOf("$");
                if (z === 0) {
                    return false;
                }
                let f = str.indexOf("`");
                if (f === 0) {
                    return false;
                }

                let h = str.indexOf("&");
                if (h === 0) {
                    return false;
                }

                let j = str.indexOf("+");
                if (j === 0) {
                    return false;
                }
                let k = str.indexOf("*");
                if (k === 0) {
                    return false;
                }
                let l = str.indexOf("/");
                if (l === 0) {
                    return false;
                }
                let m = str.indexOf("=");
                if (m === 0) {
                    return false;
                }
                let n = str.indexOf("?");
                if (n === 0) {
                    return false;
                }
                let o = str.indexOf("^");
                if (o === 0) {
                    return false;
                }
                let p = str.indexOf("{");
                if (p === 0) {
                    return false;
                }
                let q = str.indexOf("}");
                if (q === 0) {
                    return false;
                }
                let r = str.indexOf("|");
                if (r === 0) {
                    return false;
                }
                let s = str.indexOf("~");
                if (s === 0) {
                    return false;
                }

                let t = str.indexOf("$");
                if (t === 0) {
                    return false;
                }
                let u = str.indexOf(",");
                if (u === 0) {
                    return false;
                }
                let w = str.indexOf("_");
                if (w === 0) {
                    return false;
                }
                let x = str.indexOf("-");
                if (x === 0) {
                    return false;
                }
                let g = str.indexOf("@");
                if (g === 0) {
                    return false;
                }
                else if (g !== 0) {
                    let i = str.indexOf("@") - 1;
                    let v = str.indexOf("@") + 1;
                    if (str.charAt(i) === "." || str.charAt(i) === "~" || str.charAt(i) === "," || str.charAt(i) === "#" || str.charAt(i) === "!" || str.charAt(i) === "%" || str.charAt(i) === "$" || str.charAt(i) === "'" || str.charAt(i) === "&" || str.charAt(i) === "+" || str.charAt(i) === "*" || str.charAt(i) === "-" || str.charAt(i) === "/" || str.charAt(i) === "?" || str.charAt(i) === "^" || str.charAt(i) === "_" || str.charAt(i) === "`" || str.charAt(i) === "{" || str.charAt(i) === "|" || str.charAt(i) === "}" || str.charAt(i) === "~" || str.charAt(v) === "." || str.charAt(v) === "," || str.charAt(v) === "#" || str.charAt(v) === "!" || str.charAt(v) === "%" || str.charAt(v) === "$" || str.charAt(v) === "'" || str.charAt(v) === "&" || str.charAt(v) === "+" || str.charAt(v) === "*" || str.charAt(v) === "-" || str.charAt(v) === "/" || str.charAt(v) === "?" || str.charAt(v) === "^" || str.charAt(v) === "_" || str.charAt(v) === "`" || str.charAt(v) === "{" || str.charAt(v) === "|" || str.charAt(v) === "}" || str.charAt(v) === "~") {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else {
                    return true;
                }
            }
        }
        else {
            return false;
        }
    }

    testEmailNew(e) {
        let strlength = e.length;
        let a = e.lastIndexOf(".");
        let b = e.lastIndexOf("#");
        let c = e.lastIndexOf("!");
        let d = e.lastIndexOf("%");
        let y = e.lastIndexOf("$");
        let f = e.lastIndexOf("`");
        let g = e.lastIndexOf("@");
        let h = e.lastIndexOf("&");
        let j = e.lastIndexOf("+");
        let k = e.lastIndexOf("*");
        let l = e.lastIndexOf("/");
        let m = e.lastIndexOf("=");
        let n = e.lastIndexOf("?");
        let o = e.lastIndexOf("^");
        let p = e.lastIndexOf("{");
        let q = e.lastIndexOf("}");
        let r = e.lastIndexOf("|");
        let s = e.lastIndexOf("~");
        let t = e.lastIndexOf("$");
        let u = e.lastIndexOf(",");
        let w = e.lastIndexOf("_");
        let x = e.lastIndexOf("-");
        let str = e;
        let eSubstring = e.substring(g + 1, e.length);
        let beforeatValue = e.substring(0, g - 1);
        let firstIndexofDot = '';
        if (eSubstring.length > 0) {
            firstIndexofDot = eSubstring.indexOf(".");
            let lastIndexofDot = eSubstring.lastIndexOf(".");
            let aa = lastIndexofDot + 1;
            let bb = lastIndexofDot - 1;
            let cc = firstIndexofDot + 1;
            let dd = firstIndexofDot - 1;
            if (eSubstring.charAt(aa) === "." || eSubstring.charAt(aa) === "~" || eSubstring.charAt(aa) === "," || eSubstring.charAt(aa) === "#" || eSubstring.charAt(aa) === "!" || eSubstring.charAt(aa) === "%" || eSubstring.charAt(aa) === "$" || eSubstring.charAt(aa) === "'" || eSubstring.charAt(aa) === "&" || eSubstring.charAt(aa) === "+" || eSubstring.charAt(aa) === "*" || eSubstring.charAt(aa) === "-" || eSubstring.charAt(aa) === "/" || eSubstring.charAt(aa) === "?" || eSubstring.charAt(aa) === "^" || eSubstring.charAt(aa) === "_" || eSubstring.charAt(aa) === "`" || eSubstring.charAt(aa) === "{" || eSubstring.charAt(aa) === "|" || eSubstring.charAt(aa) === "}" || eSubstring.charAt(aa) === "~" || eSubstring.charAt(bb) === "." || eSubstring.charAt(bb) === "," || eSubstring.charAt(bb) === "#" || eSubstring.charAt(bb) === "!" || eSubstring.charAt(bb) === "%" || eSubstring.charAt(bb) === "$" || eSubstring.charAt(bb) === "'" || eSubstring.charAt(bb) === "&" || eSubstring.charAt(bb) === "+" || eSubstring.charAt(bb) === "*" || eSubstring.charAt(bb) === "-" || eSubstring.charAt(bb) === "/" || eSubstring.charAt(bb) === "?" || eSubstring.charAt(bb) === "^" || eSubstring.charAt(bb) === "_" || eSubstring.charAt(bb) === "`" || eSubstring.charAt(bb) === "{" || eSubstring.charAt(bb) === "|" || eSubstring.charAt(bb) === "}" || eSubstring.charAt(bb) === "~") {
                return false;
            }
            if (eSubstring.charAt(cc) === "." || eSubstring.charAt(cc) === "~" || eSubstring.charAt(cc) === "," || eSubstring.charAt(cc) === "#" || eSubstring.charAt(cc) === "!" || eSubstring.charAt(cc) === "%" || eSubstring.charAt(cc) === "$" || eSubstring.charAt(cc) === "'" || eSubstring.charAt(cc) === "&" || eSubstring.charAt(cc) === "+" || eSubstring.charAt(cc) === "*" || eSubstring.charAt(cc) === "-" || eSubstring.charAt(cc) === "/" || eSubstring.charAt(cc) === "?" || eSubstring.charAt(cc) === "^" || eSubstring.charAt(cc) === "_" || eSubstring.charAt(cc) === "`" || eSubstring.charAt(cc) === "{" || eSubstring.charAt(cc) === "|" || eSubstring.charAt(cc) === "}" || eSubstring.charAt(cc) === "~" || eSubstring.charAt(dd) === "." || eSubstring.charAt(dd) === "," || eSubstring.charAt(dd) === "#" || eSubstring.charAt(dd) === "!" || eSubstring.charAt(dd) === "%" || eSubstring.charAt(dd) === "$" || eSubstring.charAt(dd) === "'" || eSubstring.charAt(dd) === "&" || eSubstring.charAt(dd) === "+" || eSubstring.charAt(dd) === "*" || eSubstring.charAt(dd) === "-" || eSubstring.charAt(dd) === "/" || eSubstring.charAt(dd) === "?" || eSubstring.charAt(dd) === "^" || eSubstring.charAt(dd) === "_" || eSubstring.charAt(dd) === "`" || eSubstring.charAt(dd) === "{" || eSubstring.charAt(dd) === "|" || eSubstring.charAt(dd) === "}" || eSubstring.charAt(dd) === "~") {
                return false;
            }
        }
        let eSubstringonlyDomain = eSubstring.substring(0, firstIndexofDot);
        if (a === strlength - 1 || b === strlength - 1 || c === strlength - 1 || d === strlength - 1 || e === strlength - 1 || f === strlength - 1 || g === strlength - 1 || h === strlength - 1 || j === strlength - 1 || k === strlength - 1 || l === strlength - 1 || m === strlength - 1 || n === strlength - 1 || o === strlength - 1 || p === strlength - 1 || q === strlength - 1 || r === strlength - 1 || s === strlength - 1 || t === strlength - 1 || u === strlength - 1 || w === strlength - 1 || x === strlength - 1 || y === strlength - 1) {
            return false;
        }
        else if (eSubstringonlyDomain.length > 253 || beforeatValue.length > 60 || str.length > 320) {
            return false;
        }
        else {
            let eSubstring1 = e.substring(g, a);
            return this.validationForSpecialchar(eSubstring1);
        }
    }
    validationForSpecialchar(e) {
        let regex = /^@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\-0-9]{2,253}))$/;
        let str = e;
        return regex.test(str);

    }

    testEXT(e) {
        if (/^\d+$/g.test(e)) {
            return !/\s{2,}/g.test(e);
        }
        else {
            return false;
        }
    }
    testEmail(e) {
        return /^([a-zA-Z0-9_.-]+)@([a-zA-Z0-9]+)\.([a-zA-Z]{2,5})+$/.test(e);
    }

    testPhone(e) {
        return /^\(\d{3}\)\s\d{3}-\d{4}$/.test(e);

    }
    testZip(e) {
        return /^\d\d{4}$/g.test(e);
    }
    testZip5(e) {
        return /^\d\d{4}-\d\d{3}$/g.test(e);

    }
   
    render() {
        let landing = "img/ems-logo.gif";
        return (
            <Container fluid>
                <TimeoutCounter />
                <span className={"loading " + (this.state.Loading ? "show" : "hide")}>
                    <span className="loading-div-d"></span>{" "}
                    <img src={process.env.PUBLIC_URL + landing} />{" "}
                </span>
                <div className={"toaster-div " + (this.state.toasterCla ? "show" : "hide")}>
                    <i className="fa fa-check-circle" aria-hidden="true"></i>
                    {this.state.toaster}{" "}
                </div>
                <div className={"toasterred-div " + (this.state.toasterredCla ? "show" : "hide")}>
                    <i className="fa fa-exclamation-circle" aria-hidden="true"></i>{" "}
                    {this.state.toaster}{" "}
                </div>
                <div className={"removeAlert-div " + (this.state.deleteAlert ? "show" : "hide")}>
                    <span className="alert-bg-div"></span>
                    <div className="alert-pos-x"> <i className="fa fa-bell" aria-hidden="true"></i> Are you sure you wish to delete this IP Addresses?
                        <div className="text-center-div">
                            <div className="bttn popupButton" onClick={(e) => { this.handleDeleteIpAddress() }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        this.handleDeleteIpAddress();
                                    }
                                }}
                                tabIndex={0}>
                                Yes
                            </div>
                            <div className="bttn popupButton" onClick={(e) => { this.handledeletebttn() }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        this.handledeletebttn();
                                    }
                                }}
                                tabIndex={0}>
                                No
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"modal " + (this.state.isFieldsEditModalopen ? "show" : "hide")}>
                    <span className="alert-bg-div-x"></span>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content ">
                            <div className="modal-header">
                                <h4 className="modal-title">Edit Field</h4>
                                <button type="button" className="close" onClick={this.closePopup} data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body p-3 placeholder-div">
                                <div className="row mt-1">
                                    <div className="col-md-3">
                                        <label className="small mb-1">Original Field Name{" "}</label>
                                    </div>
                                    <div className="col-md-7">
                                        <input className="form-control" value={this.state.originalFieldName} type="text" autoComplete="off" disabled />
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-md-3">
                                        <label className="small mb-1">Custom Field Name{" "}
                                            <span className="red ">*</span>
                                        </label>
                                    </div>
                                    <div className="col-md-7">
                                        <input className={"form-control " + (this.state.customField ? "redborder" : "blackborder")} value={this.state.customFieldName} onChange={this.handleCustomFieldNameChange} type="text" autoComplete="off" />
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-md-3">
                                        <label className="small mb-1">Is Active {" "}</label>
                                    </div>
                                    <div className="col-md-2">
                                        <select className="form-control"
                                            value={this.state.orderIsActiveSelectedValue}
                                            onChange={this.handleIsActiveChange}
                                            disabled={this.state.isDefault}>
                                            <option key="1" value="Yes">Yes</option>
                                            <option key="0" value="No">No</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className={"col-md-3 " + (!this.state.UnderwriterFields ? "show" : "hide")}>
                                        <label className="small mb-1">APS Required{" "}</label>
                                    </div>
                                    <div className={"col-md-2 " + (!this.state.UnderwriterFields ? "show" : "hide")}>
                                        <select className="form-control"
                                            value={this.state.orderIsRequiredSelectedValue}
                                            onChange={this.handleIsRequiredChange}
                                            disabled={this.state.isDefault}>
                                            <option key="1" value="Yes">Yes</option>
                                            <option key="0" value="No">No</option>
                                        </select>
                                    </div>
                                    <div className={"col-md-3 " + (!this.state.CarrierManagerFields ? "show" : "hide")}>
                                        <label className="small mb-1">Summary Required{" "}</label>
                                    </div>
                                    <div className={"col-md-2 " + (!this.state.CarrierManagerFields ? "show" : "hide")}>
                                        <select className="form-control"
                                            value={this.state.orderSummaryIsRequiredSelectedValue}
                                            onChange={this.handleSummaryIsRequiredChange}
                                            disabled={this.state.isDefault}>
                                            <option key="1" value="Yes">Yes</option>
                                            <option key="0" value="No">No</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1" onClick={this.handleUpdateFieldInformation_New}> Update </button>
                                <button className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 mr-1" onClick={this.closePopup}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hgtDiv pt-2 mb-2">
                    <div className="bg-gray nopadding">
                        <div className="bgwhite">
                            <div>
                                <button className="createCarrier-button" onClick={this.UserLogOut}>
                                    Logout
                                </button>
                            </div>
                            <div className="logo"><h2>Create Client </h2></div>
                            <div className="col-xl-12 px-1">
                                <div className="bgwhite">
                                    <div className="card mb-1 placeholder-div">
                                        <div className="form-row">
                                            <div className="pull-left nopadding">
                                                <label className="small mdb-1" htmlFor="inputMainContactName">
                                                    <span className="red float-none">*</span> SecretKey to Create Client
                                                </label>
                                            </div>
                                            <div className="col-md-2 pull-left nopadding">
                                                <div className="form-group">
                                                    <input className={"form-control " + (this.state.rSK ? "redborder" : "blackborder")} id="inputMainContactName" value={this.state.SK} onChange={this.handleSKChange} type="password" autoComplete="off" />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card mb-1 placeholder-div">
                                        <div className="card-header posrel">Client Details</div>
                                        <div className="card-body">                                            
                                            <div className="row no-gutters">
                                                <div className="col-md-3">
                                                    <label className="small mdb-1">
                                                        <span className="red float-none">*</span>Client
                                                        Name
                                                    </label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input className={"form-control " + (this.state.rCarrierName ? "redborder" : "blackborder")} id="inputCarrierName" value={this.state.CarrierName} onChange={this.handleCarrierNameChange} type="text" autoComplete="off" />
                                                </div>
                                            </div>
                                            <div className="row no-gutters">
                                                <div className="col-md-3">
                                                    <label className="small mdb-1">
                                                        <span className="red float-none">*</span>Company Type
                                                    </label>
                                                </div>
                                                <div className="col-md-2">
                                                    <select className={"form-control " + (this.state.rCompanyType ? "redborder" : "blackborder")} id="inputCompanyType" onChange={this.handleCompanyTypeChange} value={this.state.CompanyType}>
                                                        <option key="0" value="Select">Select</option>
                                                        {this.state.CompanyTypeList.map((item, i) => {
                                                            return (
                                                                <option key={item.value} value={item.name}>{item.name}</option>
                                                            )
                                                        }, this)}
                                                    </select>
                                                </div>
                                                <div className="col-md-3">
                                                    <label className="small mdb-1">
                                                        <span className="red float-none">*</span>Company Code
                                                    </label>
                                                </div>
                                                <div className="col-md-2">
                                                    <input className={"form-control " + (this.state.rCompanyCode ? "redborder" : "blackborder")} id="inputCompanyCode" value={this.state.CompanyCode} onChange={this.handleCompanyCodeChange} maxLength={20} type="text" autoComplete="off" />
                                                </div>
                                            </div>
                                            <div className="row no-gutters">
                                                <div className="col-md-3">
                                                    <label className="small mdb-1">
                                                        Address
                                                    </label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input className={"form-control " + (this.state.rAddress ? "redborder" : "blackborder")} id="inputAddress" value={this.state.Address} onChange={this.handleAddressChange} type="text" autoComplete="off" />
                                                </div>
                                            </div>
                                            <div className="row no-gutters">
                                                <div className="col-md-3">
                                                    <label className="small mdb-1">
                                                        <span className="red float-none">*</span>City
                                                    </label>
                                                </div>
                                                <div className="col-md-2">
                                                    <input className={"form-control " + (this.state.rCity ? "redborder" : "blackborder")} id="inputCity" value={this.state.City} onChange={this.handleCityChange} type="text" autoComplete="off" />
                                                </div>
                                                <div className="col-md-1">
                                                    <label className="small mdb-1">
                                                        <span className="red float-none">*</span>ST
                                                    </label>
                                                </div>
                                                <div className="col-md-2">
                                                    <select className={"form-control " + (this.state.rState ? "redborder" : "blackborder")} id="StateId" onChange={this.handleStateChange} value={this.state.State}>
                                                        <option key="0" value="Select">Select</option>
                                                        {this.state.StatesList.map((item, i) => {
                                                            return (
                                                                <option key={item.id} value={item.stateName}>{item.stateName}</option>
                                                            )
                                                        }, this)}
                                                    </select>
                                                </div>
                                                <div className="col-md-1">
                                                    <label className="small mdb-1">
                                                        <span className="red float-none">*</span>Zip
                                                    </label>
                                                </div>
                                                <div className="col-md-2">
                                                    <input className={"form-control " + (this.state.rZipCode ? "redborder" : "blackborder")} id="inputZipCode" maxLength={10} value={this.state.ZipCode} ref={this.zipRef} onChange={this.handleZipCodeChange} type="text" autoComplete="off" />
                                                </div>
                                                <div className="col-md-3 col-12">
                                                    <label className="small mb-1">Allow CSR Messaging{" "}</label>
                                                </div>
                                                <div className="col-md-3 col-12">
                                                    <select className="form-control col-md-8"
                                                        value={this.state.AllowCSRMessaging}
                                                        onChange={this.handleallowCarrierLeverCSRMessagingChange}>
                                                        <option key="0" value="Select">Select</option>
                                                        <option key="1" value="Yes">Yes</option>
                                                        <option key="2" value="No">No</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12 nopadding mt-20">                                          
                                            </div>


                                        </div>
                                    </div>
                                    <div className="card mb-1 placeholder-div">
                                        <div className="card-header posrel">Contact Details</div>
                                        <div className="card-body">
                                            <div className="row no-gutters">
                                                <div className="col-md-3">
                                                    <label className="small mdb-1">
                                                        Main
                                                        Contact
                                                    </label>
                                                </div>
                                                <div className="col-md-7">
                                                    <input className={"form-control " + (this.state.rMainContactName ? "redborder" : "blackborder")} id="inputMainContactName" value={this.state.MainContactName} onChange={this.handleMainContactNameChange} type="text" autoComplete="off" />
                                                </div>
                                            </div>
                                            <div className="row no-gutters">
                                                <div className="col-md-3">
                                                    <label className="small mdb-1">
                                                        Email
                                                    </label>
                                                </div>
                                                <div className="col-md-3">
                                                    <input className={"form-control " + (this.state.rMainContactEmail ? "redborder" : "blackborder")} id="inputMainContactEmail" value={this.state.MainContactEmail}
                                                        onChange={this.handleMainContactEmailChange} onBlur={this.handleblurMainContactEmailChange} type="text" autoComplete="off" />
                                                </div>
                                                <div className="col-md-3">
                                                    <label className="small mdb-1">
                                                        Phone
                                                    </label>
                                                </div>
                                                <div className="col-md-3">
                                                    <input className={"form-control " + (this.state.rMainContactPhone ? "redborder" : "blackborder")} id="inputMainContactPhone" ref={this.mcpRef} maxLength={14} value={this.state.MainContactPhone} onChange={this.handleMainContactPhoneChange} type="text" autoComplete="off" />
                                                </div>
                                            </div>
                                            <div className="row no-gutters">
                                                <div className="col-md-3">
                                                    <label className="small mdb-1">
                                                        Alternate
                                                        Contact
                                                    </label>
                                                </div>
                                                <div className="col-md-7">
                                                    <input className={"form-control " + (this.state.rAlternateContactName ? "redborder" : "blackborder")} id="inputAlternateContactName" value={this.state.AlternateContactName} onChange={this.handleAlternateContactNameChange} type="text" autoComplete="off" />
                                                </div>
                                            </div>
                                            <div className="row no-gutters">
                                                <div className="col-md-3">
                                                    <label className="small mdb-1">
                                                        Email
                                                    </label>
                                                </div>
                                                <div className="col-md-3">
                                                    <input className={"form-control " + (this.state.rAlternateContactEmail ? "redborder" : "blackborder")} id="inputAlternateContactEmail" value={this.state.AlternateContactEmail} onChange={this.handleAlternateContactEmailChange} onBlur={this.handleblurAltContactEmailChange} type="text" autoComplete="off" />
                                                </div>
                                                <div className="col-md-3">
                                                    <label className="small mdb-1">
                                                        Phone
                                                    </label>
                                                </div>
                                                <div className="col-md-3">
                                                    <input className={"form-control " + (this.state.rAlternateContactPhone ? "redborder" : "blackborder")} id="inputAlternateContactPhone" ref={this.acpRef} value={this.state.AlternateContactPhone} onChange={this.handleAlternateContactPhoneChange} type="text" autoComplete="off" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-1 placeholder-div">
                                        <div className="card-header posrel">Client Level Permissions</div>
                                        <div className="card-body">
                                            <div className="row no-gutters">
                                                <div className="col-md-3">
                                                    <label className="small mb-1">Allow Override Access {" "}</label>
                                                </div>
                                                <div className="col-md-2">
                                                    <select className="form-control"
                                                        value={this.state.allowOverrideAccess}
                                                        onChange={this.handleallowOverrideAccessChange}>
                                                        <option key="0" value="Select">Select</option>
                                                        <option key="1" value="Yes">Yes</option>
                                                        <option key="2" value="No">No</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-2"></div>
                                                <div className="col-md-3">
                                                    <label className="small mb-1">Allow Default Office for ClientAdmin Users {" "}</label>
                                                </div>
                                                <div className="col-md-2">
                                                    <select className="form-control"
                                                        value={this.state.AllowDefaultOfficeCA}
                                                        onChange={this.handleallowDefaultOfficeforClientAdminUsers}>
                                                        <option key="0" value="Select">Select</option>
                                                        <option key="1" value="Yes">Yes</option>
                                                        <option key="2" value="No">No</option>
                                                    </select>
                                                </div>

                                            </div>

                                            <div className="row no-gutters">
                                                <div className="col-md-3">
                                                    <label className="small mb-1">View Results {" "}</label>
                                                </div>
                                                <div className="col-md-2">
                                                    <select className="form-control"
                                                        value={this.state.allowViewResults}
                                                        onChange={this.handleallowViewResultsChange}>
                                                        <option key="0" value="Select">Select</option>
                                                        <option key="1" value="Yes">Yes</option>
                                                        <option key="2" value="No">No</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-2"></div>
                                                <div className="col-md-3">
                                                    <label className="small mb-1">Place Order {" "}</label>
                                                </div>
                                                <div className="col-md-2">
                                                    <select className="form-control"
                                                        value={this.state.allowPlaceOrder}
                                                        onChange={this.handleallowPlaceOrderChange}>
                                                        <option key="0" value="Select">Select</option>
                                                        <option key="1" value="Yes">Yes</option>
                                                        <option key="2" value="No">No</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row no-gutters">
                                                <div className="col-md-3">
                                                    <label className="small mb-1">View Status {" "}</label>
                                                </div>
                                                <div className="col-md-2">
                                                    <select className="form-control"
                                                        value={this.state.allowViewStatus}
                                                        onChange={this.handleallowViewStatusChange}>
                                                        <option key="0" value="Select">Select</option>
                                                        <option key="1" value="Yes">Yes</option>
                                                        <option key="2" value="No">No</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-2"></div>
                                                <div className="col-md-3">
                                                    <label className="small mb-1">View Notes {" "}</label>
                                                </div>
                                                <div className="col-md-2">
                                                    <select className="form-control"
                                                        value={this.state.allowViewNotes}
                                                        onChange={this.handleallowViewNotesChange}>
                                                        <option key="0" value="Select">Select</option>
                                                        <option key="1" value="Yes">Yes</option>
                                                        <option key="2" value="No">No</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row no-gutters">
                                                <div className="col-md-3">
                                                    <label className="small mb-1">View Auth Documents {" "}</label>
                                                </div>
                                                <div className="col-md-2">
                                                    <select className="form-control"
                                                        value={this.state.allowViewAuth}
                                                        onChange={this.handleallowViewAuthChange}>
                                                        <option key="0" value="Select">Select</option>
                                                        <option key="1" value="Yes">Yes</option>
                                                        <option key="2" value="No">No</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-5">
                                                    <label className="small mb-1">Allow Export Search Results {" "}</label>
                                                </div>
                                                <div className="col-md-2">
                                                    <select className="form-control"
                                                        value={this.state.allowExportSearchResults}
                                                        onChange={this.handleallowCarrierLevelESRChange}>
                                                        <option key="0" value="Select">Select</option>
                                                        <option key="1" value="Yes">Yes</option>
                                                        <option key="2" value="No">No</option>
                                                    </select>
                                                </div>

                                            </div>
                                            <div className="row no-gutters">
                                                <div className="col-md-3">
                                                    <label className="small mb-1">Allow Performance Metric Access {" "}</label>
                                                </div>
                                                <div className="col-md-2">
                                                    <select className="form-control"
                                                        value={this.state.allowPerformanceMetrics}
                                                        onChange={this.handleallowCarrierLevelPMAChange}>
                                                        <option key="0" value="Select">Select</option>
                                                        <option key="1" value="Yes">Yes</option>
                                                        <option key="2" value="No">No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-1 placeholder-div">
                                        <div className="card-header posrel">Client Admin User Details</div>
                                        <div className="card-body">
                                            <div className="row no-gutters">
                                                <div className="col-md-3">
                                                    <label className="small mdb-1">
                                                        <span className="red float-none">*</span>First Name
                                                    </label>
                                                </div>
                                                <div className="col-md-3">
                                                    <input className={"form-control " + (this.state.rFirstName ? "redborder" : "blackborder")} maxLength="50" id="inputFirstName" value={this.state.FirstName} onChange={this.handleFirstNameChange} type="text" autoComplete="off" />
                                                </div>
                                                <div className="col-md-3">
                                                    <label className="small mdb-1">
                                                        <span className="red float-none">*</span>Last Name
                                                    </label>
                                                </div>
                                                <div className="col-md-3">
                                                    <input className={"form-control " + (this.state.rLastName ? "redborder" : "blackborder")} maxLength="50" id="inputLastName" value={this.state.LastName} onChange={this.handleLastNameChange} type="text" autoComplete="off" />
                                                </div>
                                            </div>
                                            <div className="row no-gutters">
                                                <div className="col-md-3">
                                                    <label className="small mdb-1">
                                                        <span className="red float-none">*</span>Email
                                                    </label>
                                                </div>
                                                <div className="col-md-7">
                                                    <input className={"form-control " + (this.state.rUEmail ? "redborder" : "blackborder")} maxLength="250" id="inputUEmail" value={this.state.UEmail} onChange={this.handleUEmailChange} onBlur={this.handleblurEmailChange} type="text" autoComplete="off" />
                                                </div>
                                            </div>
                                            <div className="row no-gutters">
                                                <div className="col-md-3">
                                                    <label className="small mdb-1">
                                                        Agent Id
                                                    </label>
                                                </div>
                                                <div className="col-md-3">
                                                    <input className={"form-control " + (this.state.rAgentId ? "redborder" : "blackborder")} maxLength="20" id="inputAgentId" value={this.state.AgentId} onChange={this.handleAgentIdChange} type="text" autoComplete="off" />
                                                </div>
                                                <div className="col-md-3">
                                                    <label className="small mdb-1">
                                                        <span className="red float-none">*</span>Phone
                                                    </label>
                                                </div>
                                                <div className="col-md-3">
                                                    <input className={"form-control " + (this.state.rUPhone ? "redborder" : "blackborder")} maxLength="14" id="inputUPhone" ref={this.ucpRef}  value={this.state.UPhone} onChange={this.handleUPhoneChange} type="text" autoComplete="off" />
                                                </div>
                                            </div>
                                            <div className="row no-gutters">
                                                <div className="col-md-3">
                                                    <label className="small mdb-1">
                                                        Address
                                                    </label>
                                                </div>
                                                <div className="col-md-7">
                                                    <input className={"form-control " + (this.state.rUAddress ? "redborder" : "blackborder")} maxLength="250" id="inputUAddress" value={this.state.UAddress} onChange={this.handleUAddressChange} type="text" autoComplete="off" />
                                                </div>
                                            </div>
                                            <div className="row no-gutters">
                                                <div className="col-md-3">
                                                    <label className="small mdb-1">
                                                        <span className="red float-none">*</span>City
                                                    </label>
                                                </div>
                                                <div className="col-md-2">
                                                    <input className={"form-control " + (this.state.rUCity ? "redborder" : "blackborder")} maxLength="50" id="inputCity" value={this.state.UCity} onChange={this.handleUCityChange} type="text" autoComplete="off" />
                                                </div>
                                                <div className="col-md-1">
                                                    <label className="small mdb-1">
                                                        <span className="red float-none">*</span>ST
                                                    </label>
                                                </div>
                                                <div className="col-md-2">
                                                    <select className={"form-control " + (this.state.rUState ? "redborder" : "blackborder")} id="St" onChange={this.handleUStateChange} value={this.state.UState}>
                                                        <option key="0" value="Select">Select</option>
                                                        {this.state.StatesList.map((item, i) => {
                                                            return (
                                                                <option key={item.id} value={item.stateName}>{item.stateName}</option>
                                                            )
                                                        }, this)}
                                                    </select>
                                                </div>
                                                <div className="col-md-1">
                                                    <label className="small mdb-1">
                                                        <span className="red float-none">*</span>Zip
                                                    </label>
                                                </div>
                                                <div className="col-md-2">
                                                    <input className={"form-control " + (this.state.rUZip ? "redborder" : "blackborder")} maxLength="10" id="inputZipCode" value={this.state.UZip} ref={this.uzipRef} onChange={this.handleUZipChange} type="text" autoComplete="off" />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="card mb-1 placeholder-div">
                                        <div className="card-header posrel">Ordering Office</div>
                                        <div className="card-body">
                                            <div className="row no-gutters">
                                                <div className="col-md-3">
                                                    <label className="small mdb-1">
                                                        <span className="red float-none">*</span>Office Name
                                                    </label>
                                                </div>
                                                <div className="col-md-7">
                                                    <input className={"form-control " + (this.state.rOOName ? "redborder" : "blackborder")} id="inputOOName" value={this.state.OOName} onChange={this.handleOONameChange} type="text" autoComplete="off" />
                                                </div>
                                            </div>
                                            <div className="row no-gutters">
                                                <div className="col-md-3">
                                                    <label className="small mdb-1">
                                                        Office
                                                        Code
                                                    </label>
                                                </div>
                                                <div className="col-md-7">
                                                    <input className={"form-control " + (this.state.rOOCode ? "redborder" : "blackborder")} id="inputCode" value={this.state.OOCode} onChange={this.handleOOCodeChange} type="text" autoComplete="off" />
                                                </div>
                                            </div>
                                            <div className="row no-gutters">
                                                <div className="col-md-3">
                                                    <label className="small mdb-1">
                                                        Office Account Number
                                                    </label>
                                                </div>
                                                <div className="col-md-7">
                                                    <input className={"form-control " + (this.state.rOOAccountNo ? "redborder" : "blackborder")} id="inputAccNo" value={this.state.OOAccountNo} onChange={this.handleOOAccNumChange} type="text" autoComplete="off" />
                                                </div>
                                            </div>
                                            <div className="row no-gutters">
                                                <div className="col-md-3">
                                                    <label className="small mdb-1">
                                                        Email
                                                    </label>
                                                </div>
                                                <div className="col-md-7">
                                                    <input className={"form-control " + (this.state.rOOEmail ? "redborder" : "blackborder")} id="inputOEmail" value={this.state.OOEmail}
                                                        onChange={this.handleOOEmailChange} onBlur={this.handleblurOOEmailChange} type="text" autoComplete="off" />
                                                </div>
                                            </div>
                                            <div className="row no-gutters">
                                                <div className="col-md-3">
                                                    <label className="small mdb-1">
                                                        Phone
                                                    </label>
                                                </div>
                                                <div className="col-md-7">
                                                    <input className={"form-control " + (this.state.rOOPhone ? "redborder" : "blackborder")} id="inputPhone" ref={this.ocpRef} value={this.state.OOPhone} onChange={this.handleOOPhoneChange} type="text" autoComplete="off" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="bgwhite">
                                        <div className="card mb-1 placeholder-div">
                                            <div className="card-header posrel">AVAILABLE PRODUCTS</div>
                                            <div className="row no-gutters">
                                                <div className="col-md-4">
                                                    <label className="small mdb-1"><span className="red float-none">*</span>Available Products</label>
                                                </div>
                                                <div className="col-md-2">
                                                    <input
                                                        type="checkbox"
                                                        id="summ"
                                                        className="pull-left"
                                                        onChange={this.handleAPSChecked}
                                                        checked={this.state.IsAPSChecked}
                                                        style={{ marginTop: "15px", marginLeft: "10px" }}
                                                    />
                                                    <label className="small leftside" htmlFor="medical" style={{ top: "11px" }}>
                                                        <p>APS</p>
                                                    </label>
                                                </div>
                                                <div className="col-md-3">
                                                    <label className="small mdb-1">
                                                        <span className={"red float-left " + (this.state.ShowAPSDiv ? "show" : "hide") || (this.state.ShowAPSDiv && this.state.ShowSummaryDiv ? "show" : "hide")}>*</span>APS Account #
                                                    </label>
                                                </div>
                                                <div className="col-md-3">
                                                    <input className={"form-control " + (this.state.rCropNumber ? "redborder" : "blackborder")} id="inputCropNumber" value={this.state.CropNumber} onChange={this.handleCropNumberChange} type="text" autoComplete="off" />
                                                </div>
                                            </div>
                                            <div className="row no-gutters">
                                                <div className="col-md-4">
                                                </div>
                                                <div className="col-md-2">
                                                    <input
                                                        type="checkbox"
                                                        id="summ"
                                                        className="pull-left"
                                                        onChange={this.handleSummaryChecked}
                                                        checked={this.state.IsSummaryChecked}
                                                        style={{ marginTop: "15px", marginLeft: "10px" }}
                                                    />
                                                    <label className="small leftside" htmlFor="medical" style={{ top: "11px" }}>
                                                        <p>Summary</p>
                                                    </label>
                                                </div>
                                                <div className="col-md-3">
                                                    <label className="small mdb-1">
                                                        <span className={"red float-left " + (!this.state.ShowAPSDiv && this.state.ShowSummaryDiv ? "show" : "hide")}>*</span>Summary Account #
                                                    </label>
                                                </div>
                                                <div className="col-md-3">
                                                    <input className={"form-control " + (this.state.rSummaryCropNumber ? "redborder" : "blackborder")} id="inputCropNumber" value={this.state.SummaryCropNumber} onChange={this.handleSummaryCropNumberChange} type="text" autoComplete="off" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bgwhite">
                                        <div className="card mb-1 placeholder-div max-div-xs">
                                            <div className="card-header posrel">APS CUSTOMER SERVICE CONTACT</div>
                                            <div className="form-row">
                                                <div className="col-md-4" >
                                                    <div className="form-group">
                                                        <label className="small mdb-1" htmlFor="inputCustomerServiceContactName"><span className={"red float-left " + (this.state.ShowAPSDiv ? "show" : "hide")}>*</span>Name</label>

                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <input className={"form-control " + (this.state.rCustomerServiceContactName ? "redborder" : "blackborder")} id="inputCustomerServiceContactName" value={this.state.CustomerServiceContactName} onChange={this.handleCustomerServiceContactNameChange} type="text" autoComplete="off" />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="small mdb-1" htmlFor="inputCustomerServiceContactEmail"><span className={"red float-left  " + (this.state.ShowAPSDiv ? "show" : "hide")}>*</span>Email Address</label>

                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <input className={"form-control " + (this.state.rCustomerServiceContactEmail ? "redborder" : "blackborder")} id="inputCustomerServiceContactEmail" value={this.state.CustomerServiceContactEmail}
                                                        onChange={this.handleCustomerServiceContactEmailChange} onBlur={this.handleblurServiceContactEmailChange} type="text" autoComplete="off" />
                                                </div>

                                            </div>
                                            <div className="form-row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="small mdb-1" htmlFor="inputCustomerServiceContactPhone"><span className={"red float-left " + (this.state.ShowAPSDiv ? "show" : "hide")}>*</span>Work Phone</label>

                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <input className={"form-control " + (this.state.rCustomerServiceContactPhone ? "redborder" : "blackborder")} id="inputCustomerServiceContactPhone" value={this.state.CustomerServiceContactPhone} ref={this.ccpnRef} onChange={this.handleCustomerServiceContactPhoneChange} type="text" autoComplete="off" />
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-md-4 pull-left nopadding align-self-end">
                                                    <label className="small" htmlFor="medical">
                                                        <span
                                                            className={"red float-left " + (this.state.ShowAPSDiv ? "show" : "hide")}>*</span>
                                                        APS Calls Handled By
                                                    </label>
                                                </div>
                                                <div className="col-md-2 pull-left nopadding">
                                                    <select className={"form-control " + (this.state.rOHB ? "redborder" : "blackborder")} id="ohb" onChange={this.handleOHBChange} value={this.state.OHB}>
                                                        <option key="0" value="Select">Select</option>
                                                        <option key="1" value="Onshore">Onshore</option>
                                                        <option key="2" value="Offshore">Offshore</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4 pull-left nopadding align-self-end">

                                                    <label className="small" htmlFor="medical">
                                                        <span className={"red float-left " + (this.state.ShowAPSDiv ? "show" : "hide")}>*</span>
                                                        APS Reviews Handled By
                                                    </label>
                                                </div>
                                                <div className="col-md-2 pull-left nopadding">
                                                    <select className={"form-control " + (this.state.rRHB ? "redborder" : "blackborder")} id="ohb" onChange={this.handleRHBChange} value={this.state.RHB}>
                                                        <option key="0" value="Select">Select</option>
                                                        <option key="1" value="Onshore">Onshore</option>
                                                        <option key="2" value="Offshore">Offshore</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="bgwhite">
                                        <div className="card mb-1 placeholder-div max-div-xs">
                                            <div className="card-header posrel">UNDERWRITING CUSTOMER SERVICE CONTACT</div>
                                            <div className="form-row">
                                                <div className="col-md-4" >
                                                    <div className="form-group">
                                                        <label className="small mdb-1" htmlFor="inputCustomerServiceContactName"><span className={"red float-left " + (this.state.ShowSummaryDiv ? "show" : "hide")}>*</span>Name</label>

                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <input className={"form-control " + (this.state.rCustomerServiceContactNameforSummary ? "redborder" : "blackborder")} id="inputCustomerServiceContactName" value={this.state.CustomerServiceContactNameforSummary} onChange={this.handleCustomerServiceContactNameChangeforSummary} type="text" autoComplete="off" />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="small mdb-1" htmlFor="inputCustomerServiceContactEmail"><span className={"red float-left " + (this.state.ShowSummaryDiv ? "show" : "hide")}>*</span>Email Address</label>

                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <input className={"form-control " + (this.state.rCustomerServiceContactEmailforSummary ? "redborder" : "blackborder")} id="inputCustomerServiceContactEmail" value={this.state.CustomerServiceContactEmailforSummary}
                                                        onChange={this.handleCustomerServiceContactEmailChangeforSummary} onBlur={this.handleblurCustomerServiceContactEmailChangeforSummary} type="text" autoComplete="off" />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="small mdb-1" htmlFor="inputCustomerServiceContactPhone"><span className={"red float-left " + (this.state.ShowSummaryDiv ? "show" : "hide")}>*</span>Work Phone</label>

                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <input className={"form-control " + (this.state.rCustomerServiceContactPhoneforSummary ? "redborder" : "blackborder")} id="inputCustomerServiceContactPhone" value={this.state.CustomerServiceContactPhoneforSummary} ref={this.ccpnsRef} onChange={this.handleCustomerServiceContactPhoneChangeforSummary} type="text" autoComplete="off" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-2 placeholder-div">
                                        <div className="card-header posrel">LOGIN SECURITY SETTINGS</div>
                                        <div className="card-body mt-2">
                                            <div className="form-row">
                                                <div className="col-md-5">
                                                    <div className="form-group">
                                                        <label className="small mdb-1" htmlFor="inputPasswordUpdateDays"><span className="red float-none">*</span>Enforce Password Change After</label>

                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <input className={"form-control " + (this.state.rpassUpdateDays ? "redborder" : "blackborder")} id="inputPasswordUpdateDays" disabled={this.state.PassNeverExpires} value={this.state.DaysForEnforcePass} onChange={this.handlePassUpdateDaysChange} type="text" autoComplete="off" />
                                                </div>
                                                <label className="small mdb-1" htmlFor="inputInactiveDays">Days</label>
                                                <div className="password">
                                                    <input
                                                        type="checkbox"
                                                        id="summ"
                                                        className="pull-left"
                                                        onChange={this.handlePassNeverExpires}
                                                        checked={this.state.IsPassNeverExpires}
                                                        disabled={this.state.greycheckbox}
                                                    />
                                                    <label className="small leftside" htmlFor="medical">
                                                        <p>Password Never Expires</p>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-md-5">
                                                    <div className="form-group">
                                                        <label className="small mdb-1" htmlFor="inputRemPassDays"><span className="red float-none">*</span>Remember</label>

                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <input className={"form-control " + (this.state.rRemPasswordCount ? "redborder" : "blackborder")} id="inputRemPassDays" disabled={this.state.RememberPassword} value={this.state.RememberPassCount} onChange={this.handleRemPasswordDaysChange} type="text" autoComplete="off" />
                                                </div>
                                                <label className="small mdb-1" htmlFor="inputInactiveDays">Previous Passwords</label>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-md-5">
                                                    <div className="form-group">
                                                        <label className="small mdb-1" htmlFor="inputInactiveDays"><span className="red float-none">*</span>Inactive After</label>

                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <input className={"form-control " + (this.state.rInactiveDays ? "redborder" : "blackborder")} id="inputInactiveDays" disabled={this.state.NeverInactiveUser} value={this.state.InactiveDays} onChange={this.handleInactiveDaysChange} type="text" autoComplete="off" />
                                                </div>
                                                <label className="small mdb-1" htmlFor="inputInactiveDays">Days</label>
                                                <div className="password">
                                                    <input
                                                        type="checkbox"
                                                        id="summ"
                                                        className="pull-left"
                                                        onChange={this.handleInactiveUser}
                                                        checked={this.state.IsNeverInactiveUser}
                                                        disabled={this.state.greycheckbox}
                                                    />
                                                    <label className="small leftside" htmlFor="medical">
                                                        <p>Never Inactive</p>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-md-6">
                                                    <label className="small mdb-1" htmlFor="inputInactiveDays">Enable Public Ip Address Filtering</label>
                                                </div>
                                                <div className="col-md-2">
                                                    <label className="checkbox-inline borderone">


                                                        <Switch
                                                            defaultValue={false}
                                                            bsSize="mini"
                                                            onText="Yes"
                                                            offText="No"
                                                            value={this.state.ipAddressChange}
                                                            onChange={(el, state) => this.handleIpAddressChange(el, state)}

                                                            disabled={this.state.ipSwitchGrey}
                                                            name="enableip"
                                                        />
                                                    </label>
                                                </div>
                                            </div>

                                            <div
                                                className={
                                                    "card mb-2 placeholder-div " +
                                                    (this.state.IpAddressListing ? "d-flex" : "d-none")
                                                }
                                            >
                                                <div className="form-row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="small mdb-1" htmlFor="inputRemPassDays">Public Ip Address</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <input className={"form-control " + (this.state.rFromIpAddress ? "redborder" : "blackborder")} id="inputFromIpAddress" value={this.state.FromIpAddress} onChange={this.handleFromIpAddressChange} type="text" autoComplete="off" />
                                                    </div>
                                                    <label className="small mdb-1" htmlFor="inputRemPassDays">to</label>
                                                    <div className="col-md-3">
                                                        <input className={"form-control " + (this.state.rToIpAddress ? "redborder" : "blackborder")} id="inputToIpAddress" value={this.state.ToIpAddress} onChange={this.handleToIpAddressChange} type="text" autoComplete="off" />
                                                    </div>
                                                    <div className="col-md-1">
                                                        <button className="btn btn-blue-btnnm btn-type addbtn" onClick={this.handleAddIpAddress}>Add </button>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="table-responsive dataTable unsubmitted posrel lastwidth200 noExport-table ip-address">

                                                        <table className="table data-table dataTable table-bordered dataTable blue-border" style={{ margin: "0 !important" }}>
                                                            <thead className="thead-blue sticky-top ">
                                                                <tr>
                                                                    <th>Current Public Ip Address</th>
                                                                    <th><input
                                                                        type="checkbox"
                                                                        id=""
                                                                        className="mr-2"
                                                                        name=""
                                                                        value=""
                                                                        onChange={(el, state) => this.selectAllCheckboxes(el, state)}
                                                                        checked={this.state.markCheckbox}

                                                                    />Select All</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.IpAddressList.map((item) => (
                                                                    <tr className="" key={item}><td>{item}</td><td><input
                                                                        type="checkbox"
                                                                        id={item}
                                                                        className="checkBoxClass"
                                                                        name=""
                                                                        value={item}
                                                                        onChange={(e) => this.handleCheckbox(e, item)}

                                                                    /> </td></tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="ml-auto">
                                                        <button className="btn btn-blue-btnnm btn-type deletebtn" onClick={this.handleOpendeletebttn}>Delete </button>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                        <div className={"card mb-2 placeholder-div"}>
                                            <div className="card-body mt-2">
                                                <div className="row no-gutters">
                                                    <div className="col-md-6 card-header posrel">SSO CONNECTION</div>
                                                    <div className="col-md-6 card-header posrel text-right">Multi-Factor Authentication</div>
                                                    <div className="col-md-4">
                                                        <label className="small mdb-1 pull-left">
                                                            <span className="red float-none">*</span>Enable SSO Connection
                                                        </label>
                                                    </div>
                                                    <div className="col-md-2  pt-2">
                                                        <Switch
                                                            defaultValue={false}
                                                            bsSize="mini"
                                                            onText="Yes"
                                                            offText="No"
                                                            value={this.state.EnableSSOConnection}
                                                            onChange={(el, state) => this.handleEnableSSOConnectionSwitch(el, state)}
                                                            name="enablesso"
                                                        />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label className="small mb-1">Enable MFA</label>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <Switch
                                                            defaultValue={false}
                                                            bsSize="mini"
                                                            onText="Yes"
                                                            offText="No"
                                                            value={this.state.EnableMFA}
                                                            onChange={(el, state) => this.handleEnableMFASwitch(el, state)}
                                                            disabled={!this.state.iseNoahAdmin}
                                                            name="enableMFA"
                                                        />
                                                    </div>
                                                </div>
                                                <div className={"col-md-12 nopadding " + (this.state.vIdentifier ? "show" : "hide")}>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <label className="small mdb-1 pull-left">
                                                                <span className="red float-none">*</span>SSO Identifier
                                                            </label>
                                                        </div>
                                                        <div className="col-md-4 mt-2">
                                                            <input className={"form-control " + (this.state.rIdentifier ? "redborder" : "blackborder")} id="inputIdentifier" maxLength={20} value={this.state.Identifier} onChange={this.handleIdentifierChange} type="text" autoComplete="off" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row no-gutters mt-3 pt-2">
                                                    <div className="card-header posrel"></div>
                                                    <div className="col-md-4">
                                                        <label className="small mdb-1 pull-left">
                                                            Assign Office by Account Number
                                                        </label>
                                                    </div>
                                                    <div className="col-md-2  pt-2">
                                                        <Switch
                                                            defaultValue={false}
                                                            bsSize="mini"
                                                            onText="Yes"
                                                            offText="No"
                                                            value={this.state.EnableOfficebyAccountNum}
                                                            onChange={(el, state) => this.handleEnableOfficeAccountSwitch(el, state)}
                                                            name="enableoffice"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row no-gutters hide">
                                                <div className="col-md-8">
                                                    <label className="small mb-1">MFA Type</label>
                                                </div>
                                                <div className="col-md-4">
                                                    <select className={"form-control"} id="mfatoken" onChange={this.handleMFATokenTypeChange}
                                                        disabled={(!this.state.iseNoahAdmin ? "disabled" : "")}
                                                        value={this.state.MFATokenType}>
                                                        {this.state.MFATokenTypeList.length > 0 && this.state.MFATokenTypeList.map((item, i) => {
                                                            return (
                                                                <option key={item.id} value={item.code}>{item.tokenType}</option>
                                                            )
                                                        }, this)}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row no-gutters hide">
                                                <div className="col-md-8">
                                                    <label className="small mb-1">MFA Required (in Days)</label>
                                                </div>
                                                <div className="col-md-4">
                                                    <select className={"form-control"} id="mfatokenexpirydays" onChange={this.handleMFATokenExpiryDaysChange}
                                                        disabled={(!this.state.iseNoahAdmin ? "disabled" : "")}
                                                        value={this.state.MFATokenExpiryDays}>
                                                        {this.state.MFATokenExpiryDaysList.length > 0 && this.state.MFATokenExpiryDaysList.map((item, i) => {
                                                            return (
                                                                <option key={item.id} value={item.code}>{item.expiryDays}</option>
                                                            )
                                                        }, this)}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row no-gutters hide">
                                                <div className="col-md-8">
                                                    <label className="small mb-1">One-Time Token Expires (in Min)</label>
                                                </div>
                                                <div className="col-md-4">
                                                    <select className={"form-control"} id="DropDownmfatokenexpirymin" onChange={this.handleMFATokenExpiryMinChange}
                                                        disabled={(!this.state.iseNoahAdmin ? "disabled" : "")}
                                                        value={this.state.MFATokenExpiryMin}>
                                                        {this.state.MFATokenExpiryMinList.length > 0 && this.state.MFATokenExpiryMinList.map((item, i) => {
                                                            return (
                                                                <option key={item.id} value={item.code}>{item.expiryMin}</option>
                                                            )
                                                        }, this)}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row no-gutters hide">
                                                <div className="col-md-8">
                                                    <label className="small mb-1">Token Format</label>
                                                </div>
                                                <div className="col-md-4">
                                                    <select className={"form-control"} id="DropDownmfatokenGenType" onChange={this.handleMFATokenGenTypeChange}
                                                        disabled={(!this.state.iseNoahAdmin ? "disabled" : "")}
                                                        value={this.state.MFATokenGenType}>
                                                        {this.state.MFATokenGenTypeList.length > 0 && this.state.MFATokenGenTypeList.map((item, i) => {
                                                            return (
                                                                <option key={item.id} value={item.code}>{item.tokenGenType}</option>
                                                            )
                                                        }, this)}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="row no-gutters hide">
                                                <div className="col-md-8">
                                                    <label className="small mb-1">One Time Token Length</label>
                                                </div>
                                                <div className="col-md-4">
                                                    <input className={"form-control " + (false ? "redborder" : "blackborder")} id="inputMFATokenLength" value={this.state.MFATokenLength} disabled={!this.state.iseNoahAdmin ? "disabled" : ""} onChange={this.handleMFATokenLengthChange} maxLength="2" type="text" autoComplete="off" />
                                                </div>
                                            </div>

                                            <div className="row no-gutters hide">
                                                <div className="col-md-7">
                                                    <label className="small mb-1">Track MFA by</label>
                                                </div>
                                                <div className="col-md-5 pl-1">
                                                    <div className="form-check form-check-inline">
                                                        <div className="form-check-input">
                                                            <input
                                                                type="radio"
                                                                id="byipwithuser"
                                                                className="pull-left"
                                                                name="mfaipwithuserradio"
                                                                value="mfauseronly"
                                                                onChange={this.handleMFAIpWithUserChange}
                                                                checked={this.state.MFAUserwithIP == "mfauseronly"}
                                                                disabled={!this.state.iseNoahAdmin ? "disabled" : ""}
                                                            />
                                                        </div>
                                                        <label className="form-check-label">User Name</label>
                                                    </div>

                                                    <div className="form-check form-check-inline">
                                                        <div className="form-check-input">
                                                            <input
                                                                type="radio"
                                                                id="byipwithuser"
                                                                className="pull-left"
                                                                name="mfaipwithuserradio"
                                                                value="mfaipwithuser"
                                                                onChange={this.handleMFAIpWithUserChange}
                                                                checked={this.state.MFAUserwithIP == "mfaipwithuser"}
                                                                disabled={!this.state.iseNoahAdmin ? "disabled" : ""} />
                                                        </div>
                                                        <label className="form-check-label">IP Address with User Name</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-2 placeholder-div">
                                        <div className="card-header posrel">DATA RETENTION SETTINGS</div>
                                        <div className="card-body mt-2">
                                            <div className="form-row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="small mdb-1" htmlFor="inputRetentionPeriodDays">
                                                            <span className="red float-none">*</span>Retention Period Days
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <input className={"form-control " + (this.state.rRetentionPeriodDays ? "redborder" : "blackborder")} id="inputRetentionPeriodDays" value={this.state.RetentionPeriodDays} onChange={this.handleRetentionPeriodDaysChange} type="text" autoComplete="off" />
                                                </div>

                                            </div>
                                            <div className="form-row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="small mdb-1" htmlFor="inputProductionPurgeDays">
                                                            <span className="red float-none">*</span>Production Purge Days
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <input className={"form-control " + (this.state.rProductionPurgeDays ? "redborder" : "blackborder")} id="inputProductionPurgeDays" value={this.state.ProductionPurgeDays} onChange={this.handleProductionPurgeDaysChange} type="text" autoComplete="off" />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="small mdb-1" htmlFor="inputOfflineArchivePurgeDays">
                                                            <span className="red float-none">*</span>Offline Archive Purge Days
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <input className={"form-control " + (this.state.rOfflineArchivePurgeDays ? "redborder" : "blackborder")} id="inputOfflineArchivePurgeDays" value={this.state.OfflineArchivePurgeDays} onChange={this.handleOfflineArchivePurgeDaysChange} type="text" autoComplete="off" />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-6">

                                </div>
                            </div>
                            <div className={"card-header posrel " + (this.state.ShowAfterCompanyTypeSelection ? "show" : "hide")}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h1 className="my-0 withset">Label Configurations</h1>
                                </div>
                            </div>
                            <div className={(this.state.CompanyType == "LIFE" ? "show" : "hide")}>
                                <div className="custom-tabs flex-row d-flex">
                                    <div
                                        onClick={this.ShowPatientInfoFields}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                this.ShowPatientInfoFields();
                                            }
                                        }}
                                        tabIndex={0}
                                        className={
                                            "tab-item " +
                                            (this.state.PatientInfoFields ? "active" : "")
                                        }
                                    >
                                        Patient and Contact Info Fields
                                    </div>
                                    <div
                                        onClick={this.ShowCarrierManagerFields}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                this.ShowCarrierManagerFields();
                                            }
                                        }}
                                        tabIndex={0}
                                        className={
                                            "tab-item " +
                                            (this.state.CarrierManagerFields ? "active" : "")
                                        }
                                    >
                                        Client Manager Fields
                                    </div>
                                    <div
                                        onClick={this.ShowAgentInfoFields}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                this.ShowAgentInfoFields();
                                            }
                                        }}
                                        tabIndex={0}
                                        className={
                                            "tab-item " +
                                            (this.state.AgentInfoFields ? "active" : "")
                                        }
                                    >
                                        Agent Info Fields
                                    </div>
                                    <div
                                        onClick={this.ShowFacilityInfoFields}
                                        className={
                                            "tab-item " +
                                            (this.state.FacilityInfoFields ? "active" : "")
                                        }
                                        aria-hidden="true"
                                    >
                                        Facility / Doctor Info Fields
                                    </div>
                                    <div
                                        onClick={this.ShowUnderwriterFields}
                                        className={
                                            "tab-item " +
                                            (this.state.UnderwriterFields ? "active" : "")
                                        }
                                        aria-hidden="true"
                                    >
                                        Underwriter Fields
                                    </div>
                                </div>
                            </div>
                            <div className={(this.state.CompanyType == "LEGAL" ? "show" : "hide")}>
                                <div className="custom-tabs flex-row d-flex">
                                    <div
                                        onClick={this.ShowPatientInfoFields}
                                        className={
                                            "tab-item " +
                                            (this.state.PatientInfoFields ? "active" : "")
                                        }
                                        aria-hidden="true"
                                    >
                                        Patient and Contact Info Fields
                                    </div>
                                    <div
                                        onClick={this.ShowCarrierManagerFields}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                this.ShowCarrierManagerFields();
                                            }
                                        }}
                                        tabIndex={0}
                                        className={
                                            "tab-item " +
                                            (this.state.CarrierManagerFields ? "active" : "")
                                        }
                                    >
                                        Client Manager Fields
                                    </div>
                                    <div
                                        onClick={this.ShowAdjusterInfoFields}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                this.ShowAdjusterInfoFields();
                                            }
                                        }}
                                        tabIndex={0}
                                        className={
                                            "tab-item " +
                                            (this.state.AdjusterInfoFields ? "active" : "")
                                        }
                                    >
                                        Adjuster Info Fields
                                    </div>
                                    <div
                                        onClick={this.ShowFacilityInfoFields}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                this.ShowFacilityInfoFields();
                                            }
                                        }}
                                        tabIndex={0}
                                        className={
                                            "tab-item " +
                                            (this.state.FacilityInfoFields ? "active" : "")
                                        }
                                    >
                                        Facility / Doctor Info Fields
                                    </div>
                                    <div
                                        onClick={this.ShowAttorneyInfoFields}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                this.ShowAttorneyInfoFields();
                                            }
                                        }}
                                        tabIndex={0}
                                        className={
                                            "tab-item " +
                                            (this.state.AttorneyInfoFields ? "active" : "")
                                        }
                                    >
                                        Attorney Info Fields
                                    </div>
                                    <div
                                        onClick={this.ShowUnderwriterFields}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                this.ShowUnderwriterFields();
                                            }
                                        }}
                                        className={
                                            "tab-item " +
                                            (this.state.UnderwriterFields ? "active" : "")
                                        }
                                    >
                                        Underwriter Fields
                                    </div>
                                </div>
                            </div>
                            <div className={"row " + (this.state.ShowFieldSetup ? "show" : "hide")}>
                                <div className="col-md-12">
                                    <div className="table-responsive dataTable unsubmitted posrel lastwidth245 noExport-table" style={{ marginTop: "5px" }}>
                                        <div className="col-md-2 searchoff nopadding">
                                            <span className="show-title">Show</span>
                                        </div>
                                        <div className="col-md-2 entriesoff nopadding">
                                            <span className="show-entries">entries</span>
                                        </div>
                                        <div className="searchoff-x">
                                            <span className="show-title-x">Search</span>
                                        </div>
                                        <MaterialTable
                                            title=""
                                            className="table data-table dataTable table-bordered"
                                            columns={[
                                                { title: 'Original Field Name', field: 'originalFieldName' },
                                                { title: 'Custom Field Name', field: 'customFieldName' },
                                                { title: 'Active', field: 'isActive', render: rowData => (rowData.isActive ? "Yes" : "No") },
                                                { title: 'APS Required / Mandatory', field: 'isRequired', render: rowData => (rowData.isRequired ? "Yes" : "No") },
                                                { title: 'Summary Required / Mandatory', field: 'summaryIsRequired', render: rowData => (rowData.summaryIsRequired ? "Yes" : "No") },
                                                {
                                                    title: 'Action ', field: 'action', render: rowData => <span>
                                                        <span className="edit-btnm editActionE" onClick={() => this.onClickopenFieldEditPopup(rowData)}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    this.onClickopenFieldEditPopup(rowData);
                                                                }
                                                            }}
                                                            tabIndex={0}><img src="img/edit-icon.png" alt="Edit" /> Edit</span>
                                                    </span>
                                                },
                                            ]}
                                            data={this.state.RequiredFieldsList}
                                            options={{
                                                exportButton: true,
                                                search: true,
                                                paginationType: "stepped"
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={"row " + (this.state.PatientInfoFields ? "show" : "hide")}>
                                <div className="col-md-12">
                                    <div className="table-responsive dataTable unsubmitted posrel lastwidth245 noExport-table" style={{ marginTop: "5px" }}>
                                        <div className="col-md-2 searchoff nopadding">
                                            <span className="show-title">Show</span>
                                        </div>
                                        <div className="col-md-2 entriesoff nopadding">
                                            <span className="show-entries">entries</span>
                                        </div>
                                        <div className="searchoff-x">
                                            <span className="show-title-x">Search</span>
                                        </div>
                                        <MaterialTable
                                            title=""
                                            className="table data-table dataTable table-bordered"
                                            columns={[
                                                { title: 'Original Field Name', field: 'originalFieldName' },
                                                { title: 'Custom Field Name', field: 'customFieldName' },
                                                { title: 'Active', field: 'isActive', render: rowData => (rowData.isActive ? "Yes" : "No") },
                                                { title: 'APS Required / Mandatory', field: 'isRequired', render: rowData => (rowData.isRequired ? "Yes" : "No") },
                                                { title: 'Summary Required / Mandatory', field: 'summaryIsRequired', render: rowData => (rowData.summaryIsRequired ? "Yes" : "No") },
                                                {
                                                    title: 'Action ', field: 'action', render: rowData => <span>
                                                        <span className="edit-btnm editActionE" onClick={() => this.onClickopenFieldEditPopup(rowData)}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    this.onClickopenFieldEditPopup(rowData);
                                                                }
                                                            }}
                                                            tabIndex={0}><img src="img/edit-icon.png" alt="Edit" /> Edit</span>
                                                    </span>
                                                },
                                            ]}
                                            data={this.state.RequiredFieldsSec1}
                                            options={{
                                                exportButton: true,
                                                search: true,
                                                paginationType: "stepped"
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={"row " + (this.state.AgentInfoFields ? "show" : "hide")}>
                                <div className="col-md-12">
                                    <div className="table-responsive dataTable unsubmitted posrel lastwidth245 noExport-table" style={{ marginTop: "5px" }}>
                                        <div className="col-md-2 searchoff nopadding">
                                            <span className="show-title">Show</span>
                                        </div>
                                        <div className="col-md-2 entriesoff nopadding">
                                            <span className="show-entries">entries</span>
                                        </div>
                                        <div className="searchoff-x">
                                            <span className="show-title-x">Search</span>
                                        </div>
                                        <MaterialTable
                                            title=""
                                            className="table data-table dataTable table-bordered"
                                            columns={[
                                                { title: 'Original Field Name', field: 'originalFieldName' },
                                                { title: 'Custom Field Name', field: 'customFieldName' },
                                                { title: 'Active', field: 'isActive', render: rowData => (rowData.isActive ? "Yes" : "No") },
                                                { title: 'APS Required / Mandatory', field: 'isRequired', render: rowData => (rowData.isRequired ? "Yes" : "No") },
                                                { title: 'Summary Required / Mandatory', field: 'summaryIsRequired', render: rowData => (rowData.summaryIsRequired ? "Yes" : "No") },
                                                {
                                                    title: 'Action ', field: 'action', render: rowData => <span>
                                                        <span className="edit-btnm editActionE" onClick={() => this.onClickopenFieldEditPopup(rowData)}><img src="img/edit-icon.png" /> Edit</span>
                                                    </span>
                                                },
                                            ]}
                                            data={this.state.RequiredFieldsSec4}
                                            options={{
                                                exportButton: true,
                                                search: true,
                                                paginationType: "stepped"
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={"row " + (this.state.FacilityInfoFields ? "show" : "hide")}>
                                <div className="col-md-12">
                                    <div className="table-responsive dataTable unsubmitted posrel lastwidth245 noExport-table" style={{ marginTop: "5px" }}>
                                        <div className="col-md-2 searchoff nopadding">
                                            <span className="show-title">Show</span>
                                        </div>
                                        <div className="col-md-2 entriesoff nopadding">
                                            <span className="show-entries">entries</span>
                                        </div>
                                        <div className="searchoff-x">
                                            <span className="show-title-x">Search</span>
                                        </div>
                                        <MaterialTable
                                            title=""
                                            className="table data-table dataTable table-bordered"
                                            columns={[
                                                { title: 'Original Field Name', field: 'originalFieldName' },
                                                { title: 'Custom Field Name', field: 'customFieldName' },
                                                { title: 'Active', field: 'isActive', render: rowData => (rowData.isActive ? "Yes" : "No") },
                                                { title: 'APS Required / Mandatory', field: 'isRequired', render: rowData => (rowData.isRequired ? "Yes" : "No") },
                                                { title: 'Summary Required / Mandatory', field: 'summaryIsRequired', render: rowData => (rowData.summaryIsRequired ? "Yes" : "No") },
                                                {
                                                    title: 'Action ', field: 'action', render: rowData => <span>
                                                        <span className="edit-btnm editActionE" onClick={() => this.onClickopenFieldEditPopup(rowData)}><img src="img/edit-icon.png" /> Edit</span>
                                                    </span>
                                                },
                                            ]}
                                            data={this.state.RequiredFieldsSec5}
                                            options={{
                                                exportButton: true,
                                                search: true,
                                                paginationType: "stepped"
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={"row " + (this.state.CarrierManagerFields ? "show" : "hide")}>
                                <div className="col-md-12">
                                    <div className="table-responsive dataTable unsubmitted posrel lastwidth245 noExport-table" style={{ marginTop: "5px" }}>
                                        <div className="col-md-2 searchoff nopadding">
                                            <span className="show-title">Show</span>
                                        </div>
                                        <div className="col-md-2 entriesoff nopadding">
                                            <span className="show-entries">entries</span>
                                        </div>
                                        <div className="searchoff-x">
                                            <span className="show-title-x">Search</span>
                                        </div>
                                        <MaterialTable
                                            title=""
                                            className="table data-table dataTable table-bordered"
                                            columns={[
                                                { title: 'Original Field Name', field: 'originalFieldName' },
                                                { title: 'Custom Field Name', field: 'customFieldName' },
                                                { title: 'Active', field: 'isActive', render: rowData => (rowData.isActive ? "Yes" : "No") },
                                                { title: 'APS Required / Mandatory', field: 'isRequired', render: rowData => (rowData.isRequired ? "Yes" : "No") },
                                                {
                                                    title: 'Action ', field: 'action', render: rowData => <span>
                                                        <span className="edit-btnm editActionE" onClick={() => this.onClickopenFieldEditPopup(rowData)}><img src="img/edit-icon.png" /> Edit</span>
                                                    </span>
                                                },
                                            ]}
                                            data={this.state.RequiredFieldsSec14}
                                            options={{
                                                exportButton: true,
                                                search: true,
                                                paginationType: "stepped"
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={"row " + (this.state.AttorneyInfoFields ? "show" : "hide")}>
                                <div className="col-md-12">
                                    <div className="table-responsive dataTable unsubmitted posrel lastwidth245 noExport-table" style={{ marginTop: "5px" }}>
                                        <div className="col-md-2 searchoff nopadding">
                                            <span className="show-title">Show</span>
                                        </div>
                                        <div className="col-md-2 entriesoff nopadding">
                                            <span className="show-entries">entries</span>
                                        </div>
                                        <div className="searchoff-x">
                                            <span className="show-title-x">Search</span>
                                        </div>
                                        <MaterialTable
                                            title=""
                                            className="table data-table dataTable table-bordered"
                                            columns={[
                                                { title: 'Original Field Name', field: 'originalFieldName' },
                                                { title: 'Custom Field Name', field: 'customFieldName' },
                                                { title: 'Active', field: 'isActive', render: rowData => (rowData.isActive ? "Yes" : "No") },
                                                { title: 'APS Required / Mandatory', field: 'isRequired', render: rowData => (rowData.isRequired ? "Yes" : "No") },
                                                { title: 'Summary Required / Mandatory', field: 'summaryIsRequired', render: rowData => (rowData.summaryIsRequired ? "Yes" : "No") },
                                                {
                                                    title: 'Action ', field: 'action', render: rowData => <span>
                                                        <span className="edit-btnm editActionE" onClick={() => this.onClickopenFieldEditPopup(rowData)}><img src="img/edit-icon.png" /> Edit</span>
                                                    </span>
                                                },
                                            ]}
                                            data={this.state.RequiredFieldsSec7}
                                            options={{
                                                exportButton: true,
                                                search: true,
                                                paginationType: "stepped"
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={"row " + (this.state.AdjusterInfoFields ? "show" : "hide")}>
                                <div className="col-md-12">
                                    <div className="table-responsive dataTable unsubmitted posrel lastwidth245 noExport-table" style={{ marginTop: "5px" }}>
                                        <div className="col-md-2 searchoff nopadding">
                                            <span className="show-title">Show</span>
                                        </div>
                                        <div className="col-md-2 entriesoff nopadding">
                                            <span className="show-entries">entries</span>
                                        </div>
                                        <div className="searchoff-x">
                                            <span className="show-title-x">Search</span>
                                        </div>
                                        <MaterialTable
                                            title=""
                                            className="table data-table dataTable table-bordered"
                                            columns={[
                                                { title: 'Original Field Name', field: 'originalFieldName' },
                                                { title: 'Custom Field Name', field: 'customFieldName' },
                                                { title: 'Active', field: 'isActive', render: rowData => (rowData.isActive ? "Yes" : "No") },
                                                { title: 'APS Required / Mandatory', field: 'isRequired', render: rowData => (rowData.isRequired ? "Yes" : "No") },
                                                { title: 'Summary Required / Mandatory', field: 'summaryIsRequired', render: rowData => (rowData.summaryIsRequired ? "Yes" : "No") },
                                                {
                                                    title: 'Action ', field: 'action', render: rowData => <span>
                                                        <span className="edit-btnm editActionE" onClick={() => this.onClickopenFieldEditPopup(rowData)}><img src="img/edit-icon.png" /> Edit</span>
                                                    </span>
                                                },
                                            ]}
                                            data={this.state.RequiredFieldsSec13}
                                            options={{
                                                exportButton: true,
                                                search: true,
                                                paginationType: "stepped"
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={"row " + (this.state.UnderwriterFields ? "show" : "hide")}>
                                <div className="col-md-12">
                                    <div className="table-responsive dataTable unsubmitted posrel lastwidth245 noExport-table" style={{ marginTop: "5px" }}>
                                        <div className="col-md-2 searchoff nopadding">
                                            <span className="show-title">Show</span>
                                        </div>
                                        <div className="col-md-2 entriesoff nopadding">
                                            <span className="show-entries">entries</span>
                                        </div>
                                        <div className="searchoff-x">
                                            <span className="show-title-x">Search</span>
                                        </div>
                                        <MaterialTable
                                            title=""
                                            className="table data-table dataTable table-bordered"
                                            columns={[
                                                { title: 'Original Field Name', field: 'originalFieldName' },
                                                { title: 'Custom Field Name', field: 'customFieldName' },
                                                { title: 'Active', field: 'isActive', render: rowData => (rowData.isActive ? "Yes" : "No") },
                                                { title: 'Summary Required / Mandatory', field: 'summaryIsRequired', render: rowData => (rowData.summaryIsRequired ? "Yes" : "No") },
                                                {
                                                    title: 'Action ', field: 'action', render: rowData => <span>
                                                        <span className="edit-btnm editActionE" onClick={() => this.onClickopenFieldEditPopup(rowData)}><img src="img/edit-icon.png" /> Edit</span>
                                                    </span>
                                                },
                                            ]}
                                            data={this.state.RequiredFieldsSec14}
                                            options={{
                                                exportButton: true,
                                                search: true,
                                                paginationType: "stepped"
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="text-right py-2">
                                <ul className="btn-alignment-x float-none">
                                    <li>
                                        <button className="btn btn-blue-btnn btn-type" onClick={this.handleClearDetails}>Clear </button>
                                    </li>
                                    <li>
                                        <button className="btn btn-blue-btnn btn-type btn-auto-width" onClick={this.handleSubmitCompanyDetails}>Create </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        );
    }
}
export default CreateDatabase;
