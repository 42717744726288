import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router';
import { Login } from './components/Login.js?version=1.0.13';
import { FetchData } from './components/FetchData.js?version=1.0.13';
import { PlaceOrder } from './components/PlaceOrder.js?version=1.0.13';
import { Unsubmitted } from './components/Unsubmitted.js?version=1.0.13';
import { Search } from './components/Search.js?version=1.0.13';
import { Result } from './components/Result.js?version=1.0.13';
import { Account } from './components/Account.js?version=1.0.13';
import { Performance } from './components/Performance.js?version=1.0.13';
import { Dashboard } from './components/Dashboard.js?version=1.0.13';
import { CaseDetails } from './components/CaseDetails.js?version=1.0.13';
import { Confirmation } from './components/Confirmation.js?version=1.0.13';
import { Forgot } from './components/Forgot.js?version=1.0.13';
import { Carrier } from './components/Carrier.js?version=1.0.13';
import { Error } from './components/Error.js?version=1.0.13';
import { Register } from './components/Register.js?version=1.0.13';
import { AccountConfirmation } from './components/AccoutConfirmation.js?version=1.0.13';
import { ResetPassword } from './components/Reset.js?version=1.0.13';
import { ForgotPassword } from './components/ForgotPassword.js?version=1.0.13';
import CreateDatabase from './components/CreateDatabase';
import { ErrorDetails } from './components/ErrorDetails';
import { InboundOrders } from './components/InboundOrders';
import { ReportData } from './components/ReportData';
import { B2BConfiguration } from './components/B2BConfiguration';
import { ValidateSSOUser } from './components/ValidateSSOUser';
import { LogoutSSO } from './components/LogoutSSO'
import { LoginErrorSSO } from './components/LoginErrorSSO'
import './custom.css?version=1.0.14'
import { EventType } from './components/EventType';
import { CaseDetailsSSO } from './components/CaseDetailsSSO.js?version=1.0.13';
import { SearchSSO } from './components/SearchSSO.js?version=1.0.13';
import { isNullOrUndefined } from 'util';
import { ChangePassword } from './components/ChangePassword.js?version=1.0.13';
import { SummaryEdit } from './components/SummaryEdit.js?version=1.0.13';
import { CreateDatabaseLogin } from './components/CreateDatabaseLogin.js?version=1.0.13';
const PrivateRoute = ({ component: Component, ...rest }) => {
    const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';

    return isAuthenticated ? (
        <Component {...rest} />
    ) : (
            <Redirect to="/CreateCarrierLogin" replace />
    );
};
export default class App extends Component {
    static displayName = App.name;


    componentDidMount() {
        let currentComponent = this;
        window.addEventListener('popstate', (event) => {
            let IsSSOUser = localStorage.getItem("IsSSOUser");
            let SyncClicked = localStorage.getItem("SyncClicked");
            if (IsSSOUser == 'true' && SyncClicked =='false') {
                if (window.confirm("Are you sure you want to navigate away from this page? Press OK to continue, or Cancel to stay on the current page.")) {
                    currentComponent.SSOUserLogoutUrl();
                    event.preventDefault();
                    localStorage.clear();
                }
            }
        });
    }
    componentWillUnmount() {
        window.removeEventListener('popstate', (event) => {
            console.log('removed');
        });
    }
    componentDidCatch(err) {
      window.location.href = "/";
    }    
    SSOUserLogoutUrl() {
        let CompanyCode = localStorage.getItem("Companycode");
        let token = localStorage.getItem("Euid");

        fetch('api/login/SSOUserLogout', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Companycode": CompanyCode,
                "Token": token
            }
        }).then(response => response.json())
            .then(function (res) {
                if (!isNullOrUndefined(res.ssoLogOutURL)) {
                    window.location.href = res.ssoLogOutURL;
                }
                else {
                    window.location.href = "/LogoutSSO";
                }
            })
    }

    render() {
        return (
            <div>
                {/*<Route path='*' component={NotFound} />*/}
                <Route exact path='/' render={props => (
                    <Login {...props} />
                )} />
                <Route exact path='/validatessouser' render={props => (
                    <ValidateSSOUser {...props} />
                )} />
                <Route exact path='/place-order' render={props => (
                    <PlaceOrder {...props} />
                )} />

                <Route exact path='/order-report' render={props => (
                    <ReportData {...props} />
                )} />

                <Route exact path='/unsubmitted-order' render={props => (
                    <Unsubmitted {...props} />
                )} />

                <Route exact path='/search' render={props => (
                    <Search {...props} />
                )} />
                <Route exact path='/result' render={props => (
                    <Result {...props} />
                )} />

                <Route exact path='/account' render={props => (
                    <Account {...props} />
                )} />
                <Route exact path='/performance' render={props => (
                    <Performance {...props} />
                )} />

                <Route exact path='/dashboard' render={props => (
                    <Dashboard {...props} />
                )} />

                <Route exact path='/case-details' render={props => (
                    <CaseDetails {...props} />
                )} />

                <Route exact path='/confirmation' render={props => (
                    <Confirmation {...props} />
                )} />

                <Route exact path='/forgot-password' render={props => (
                    <Forgot {...props} />
                )} />

                <Route exact path='/carrier' render={props => (
                    <Carrier {...props} />
                )} />

                <Route exact path='/error' render={props => (
                    <Error {...props} />
                )} />

                <Route exact path='/register' render={props => (
                    <Register {...props} />
                )} />

                <Route exact path='/account-confirmation' render={props => (
                    <AccountConfirmation {...props} />
                )} />

                <Route exact path='/reset' render={props => (
                    <ResetPassword {...props} />
                )} />
                <Route exact path='/ForgotPassword' render={props => (
                    <ForgotPassword {...props} />
                )} />
                <Route exact path='/CreateCarrier' render={props => (
                    <PrivateRoute
                        component={CreateDatabase}
                        {...props}
                    />
                )} />

                <Route exact path='/Error-Details' render={props => (
                    <ErrorDetails {...props} />
                )} />

                <Route exact path='/Inbound-Orders' render={props => (
                    <InboundOrders {...props} />
                )} />

                <Route exact path='/B2BConfiguration' render={props => (
                    <B2BConfiguration {...props} />
                )} />
                <Route exact path='/LogoutSSO' render={props => (
                    <LogoutSSO {...props} />
                )} />
                <Route exact path='/LoginErrorSSO' render={props => (
                    <LoginErrorSSO {...props} />
                )} />
                <Route exact path='/case-detailsSSO' render={props => (
                    <CaseDetailsSSO {...props} />
                )} />
                <Route path='/fetch-data' component={FetchData} />
                {/*<Route exact path='/Event-Type' render={props => (*/}
                {/*    <EventType {...props} />*/}
                {/*)} />*/}

                <Route exact path='/ChangePassword' render={props => (
                    <ChangePassword {...props} />
                )} />
                
                <Route exact path='/searchSSO' render={props => (
                    <SearchSSO {...props} />
                )} />
                <Route exact path='/summaryEdit' render={props => (
                    <SummaryEdit {...props} />
                )} />
                <Route exact path='/CreateCarrierLogin' render={props => (
                    <CreateDatabaseLogin {...props} />
                )} />
            </div >
        );
    }
}
