import React, { Component } from 'react';
import Cookies from 'js-cookie';
import Api from '../serviceCall';
import { isNullOrUndefined } from 'util';
import { deviceDetect } from "react-device-detect";
import $ from 'jquery';



export class CreateDatabaseLogin extends Component {
    static displayName = CreateDatabaseLogin.name;
    constructor(props) {
        super(props);
        this.state = {
            Username: '', Password: '', sererror: false, usererror: false, loading: false, validUserName: '', isPasswordField: false, isUserNameField: true, input: {},
            errors: {}, Version: "", Env: "", Date: "", 
             CopyRightYear: new Date().getFullYear(), ssousererror: false,  PrivacyPolicyURL: "",  passerror: false, DisableEnterKey: true,
            Source: "", OrderFacilityId: "", EncryptedUrlParams: "", toasterredCla: false, toaster: ""
        };
        this.signInSubmit = this.signInSubmit.bind(this);
        this.handleUNChange = this.handleUNChange.bind(this);
        this.handleKeyDownUN = this.handleKeyDownUN.bind(this);
        this.handleKeyDownPass = this.handleKeyDownPass.bind(this);
        this.handlePSChange = this.handlePSChange.bind(this);
        this.checkUN = this.checkUN.bind(this);
        this.toasterredfun2 = this.toasterredfun2.bind(this);
    }
    componentWillMount() {
        localStorage.clear();
        let currentComponent = this;
        let windowlocation = window.location.href;
        const urlParts = windowlocation.split('?');
        let queryString = "";
        fetch('services/api/GetVersionConfiguration', {
            method: 'Get',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',

            },

        }).then(function (response) {
            return response.json();

        }).then(function (result) {
            if (result.statusCode === 100) {
                currentComponent.setState({ Version: result.version, Env: result.environment, Date: result.buildDate });
            }
            else {
                currentComponent.setState({ Version: "", Env: "N/A", Date: "N/A" });
            }
        });
        Api.Get('api/login/GetPrivacyPolicy', currentComponent).then(function (response) {
            if (response.statusCode === 100) {
                currentComponent.setState({ PrivacyPolicyURL: response.privacyPolicyURL });
            }
            else {
                currentComponent.setState({ PrivacyPolicyURL: "https://enoahisolution.com/privacy-policy/" });
            }
        });

        if (urlParts.length > 1) {
            queryString = urlParts[1];
            if (!isNullOrUndefined(queryString) && queryString.trim().length > 0) {
                fetch('api/Login/DecryptUrlString', {
                    method: 'post',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ "UrlString": queryString })
                }).then(function (response) {
                    return response.json();
                }).then(function (result) {
                    if (result.statusMessage === "Success") {
                        currentComponent.setState({
                            Source: result.source, OrderFacilityId: result.orderFacilityId, EncryptedUrlParams: queryString
                        })
                    }
                }).catch(function (error) {
                    this.setState({ Loading: false });
                    if (error === "TypeError: Failed to fetch") {
                        currentComponent.toasterredfun2("Check your internet connection.");
                    }
                    else {
                        currentComponent.toasterredfun2(error);
                    }
                });
            }
        }
        else {
            currentComponent.setState({
                Source: '0'
            })
        }
    }


    signInSubmit(e) {
        e.preventDefault();
        this.Signin();
    }

    handleUNChange(e) {
        if (e.target.value.length !== 0) {
            if (this.checkUN(e.target.value.trim())) {
                this.setState({ usererror: false, somethingwentErr: false, passerror: false, sererror: false });
                this.setState({ Username: e.target.value.trim() });
            }
        }
        else {
            this.setState({ usererror: false, somethingwentErr: false, passerror: false, sererror: false });
            this.setState({ Username: e.target.value });
        }
    }

    handlePSChange(e) {
        if (/^.*$/g.test(e.target.value.trim())) {
            this.setState({ usererror: false, somethingwentErr: false, passerror: false, sererror: false,  Password: e.target.value.trim() });
            return true;
        }
    }

    checkUN(e) {
        return !(e.length > 1 && e.split('.').length > 2) && /^[a-zA-Z0-9@.]*$/g.test(e);
    }
    
    handleKeyDownUN(event) {
        this.setState({ usererror: false, somethingwentErr: false, passerror: false, sererror: false});
        let key = event.which || event.key;
        if (key === 13 || key === "13") {
            if (this.state.Username.length !== 0 && this.state.Password.length !== 0) {
                event.preventDefault();
                if (this.state.DisableEnterKey) {
                    this.setState({ DisableEnterKey: false, loading: true })
                    this.Signin();
                }
            }
        }
    }

    handleKeyDownPass(e) {
        this.setState({ usererror: false, somethingwentErr: false, passerror: false, sererror: false });
        let key = e.which || e.key;
        if (key === 13 || key === "13") {
            if (this.state.Username.length != 0 && this.state.Password.length != 0 ) {
                e.preventDefault();
                if (this.state.DisableEnterKey) {
                    this.setState({ DisableEnterKey: false, loading: true })
                    this.Signin();
                }
            }
        }
    }  
    toasterredfun2(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
            }.bind(this), 6000);
    }
        Signin() {
            let UN = this.state.Username, Pswd = this.state.Password, valid = true
            let currentComponent = this;
            if (UN == null || UN.trim().length === 0) {
                this.setState({ usererror: true });
                valid = false;
            }
            if (Pswd == null || Pswd.trim().length === 0) {
                this.setState({ passerror: true });
                valid = false;
            }        
            this.setState({ loading: valid, DisableEnterKey: !valid });
            if (valid) {
                let antiToken = Cookies.get('XSRF-TOKEN');
                fetch('api/login/CheckLoginforCreatecarrier', {
                    method: 'post',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        "X-XSRF-Token": antiToken
                    },
                    body: JSON.stringify({
                        "UserName": Api.encryptData(UN),
                        "Password": Api.encryptData(Pswd),
                        "BrowserType": deviceDetect().browserName,
                        "BrowserVersion": deviceDetect().browserFullVersion
                    })
                }).then(function (response) {
                    if (!response.ok) {
                        return response;
                    }
                    return response.json();
                }).then(function (resul) {
                    currentComponent.setState({ loading: false });

                    if (resul.status === 503) {
                        currentComponent.setState({ DisableEnterKey: true });
                        currentComponent.toasterredfun2("ERROR-Site unavailable, please check the announcement section for more details.");
                    }
                    else if (resul.statusMessage !== "Success") {
                        currentComponent.setState({ DisableEnterKey: true });
                        if (resul.statusCode === 401) {
                            currentComponent.setState({ loading: false, usererror: false, somethingwentErr: false, passerror: false, sererror: true });
                        }
                        else if (resul == null || resul.status === 429) {
                            currentComponent.setState({ loading: false, usererror: false, somethingwentErr: false, passerror: false, sererror: false });
                            currentComponent.toasterredfun2("The number of consecutive mouse clicks exceeded. Please refresh the page or wait 7 seconds and try again.");
                        }
                        else {
                            currentComponent.setState({ loading: false, usererror: false, somethingwentErr: false, passerror: false, sererror: false });
                            currentComponent.toasterredfun2(resul.statusMessage);
                        }
                    }
                    else {
                        localStorage.setItem('isAuthenticated', 'true');
                        currentComponent.props.history.push('/createcarrier');

                    }

                }).catch(function (error) {
                    currentComponent.setState({ loading: false, usererror: false, usererror: false, somethingwentErr: true, passerror: false, sererror: false  });
                    if (error === "TypeError: Failed to fetch") {
                        currentComponent.toasterredfun2("Check your internet connection.");
                    }
                    else {
                        currentComponent.toasterredfun2(error);
                    }
                });
            }
    }

    render() {
        let landing = 'img/ems-logo.gif';
        return (
            <div>
                <div className="container-div">
                    <div className="con-div-xd lefeside-panel">


                    </div>
                    <div className="con-div-x top25">
                        <span className={"loading " + (this.state.loading ? "show" : "hide")} ><span className="loading-div-d"></span> <img src={process.env.PUBLIC_URL + landing} alt="Loading" /> </span>
                        <div className={"toasterred-div " + (this.state.toasterredCla ? "show" : "hide")}><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.toaster} </div>
                        <div className="login-page">
                            <div className="logo">
                                <img src="img/eSubmission-logo.png" alt="Logo" />
                                <h2>eNoah Online Portal </h2>
                            </div>
                            <div className="form">
                                <h5> Create Carrier Login </h5>
                                <form className="login-form">
                                    <div className={"form-group " + (this.state.isUserNameField ? "show" : "hide")}>
                                        <label className="small">Username </label>

                                        <input type="text" placeholder="User" onKeyDown={this.handleKeyDownUN} value={this.state.Username} onChange={this.handleUNChange} />
                                        <span className={"error " + (this.state.usererror ? "show" : "hide")}>User name is Invalid </span>
                                        <label className="small">Password</label>
                                        <input type="password" placeholder="Password" onKeyDown={this.handleKeyDownPass} maxLength={15} value={this.state.Password} onChange={this.handlePSChange} />
                                        <span className={"error " + (this.state.passerror ? "show" : "hide")}>Password is Invalid </span>
                                        <span className={"error " + (this.state.somethingwentErr ? "show" : "hide")}>Something Went Wrong</span>
                                        <span className={"error " + (this.state.sererror ? "show" : "hide")}>User name or Password is Invalid </span>
                                        <button className="onLogin mt-4" onClick={this.signInSubmit}>Login</button>
                                    </div>                      
                                </form>
                            </div>
                        </div>
                        <div className="footer-text text-center mb-2">Build: <span className="text-dark mr-2">{(this.state.Date === "N/A" ? "N/A" : (this.state.Date + ": v" + this.state.Version))}</span> Environment: <span className="text-dark">{this.state.Env}</span></div>
                        <div className="footer-text text-center mb-2">Copyright @ eNoah iSolutions Inc. {this.state.CopyRightYear}</div>
                        <div className="footer-text text-center">
                            <a href={this.state.PrivacyPolicyURL} rel="noopener noreferrer" target="_blank"  > Privacy Policy</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
